<template>
  <VRow 
    id="add-users"
    class="mx-0"
  >
    <VCol cols="12">
      <p
        class="mb-0" 
        style="font-size: 18px;"
      >
        You can tap into your existing Workspace network to easily add users who already moderate or participate as {{ featureName("students") }} in other {{ featureName("programs") }} within your workspace.
      </p>
    </VCol>
    <VCol 
      cols="12"
      class="pb-0"
    >
      <DataViewUI
        hide-actions
        hide-records-info
        hide-item-actions
        enable-infinite-loader
        ref="dataViewUI"
        search-prepend-icon=""
        search-label="Search..."
        search-append-icon="search"
        :data="infiniteLoad"
        :headers="headers"
        :is-loading="activity.isLoading"
        @search="onSearch"
        @infiniteLoad="onInfiniteLoad"
      >
        <template #search-prepend>
          <InfiniteAutocomplete
            v-model="programFilter"
            section="programs"
            custom-class="programs-filter mr-6"
            prepend-inner-icon="mdi-filter-variant"
            :disabled="activity.isLoading"
            :placeholder="`Filter results by ${featureName('Programs', 'singularize')}`"
            @change="onFilterData"
          />
        </template>
        <template #items-cells="props">
          <td class="simple">
            <VList color="transparent">
              <VListItem class="px-0">
                <VListItemAvatar size="36px">
                  <img :src="props.item.photo_url">
                </VListItemAvatar>
                <VListItemContent>
                  <VListItemTitle 
                    class="text-subtitle-2"
                    :style="{
                      color: getRoleForCurrentProgram(props.item) == 'none' ? '#666666' : '#000000',
                    }"
                  >
                    {{ props.item.name }}
                  </VListItemTitle>
                  <VListItemSubtitle
                    class="text-caption font-weight-medium"
                    style="color: #6E6E72;"
                  >
                    {{ props.item.email }}
                  </VListItemSubtitle>
                </VListItemContent>
              </VListItem>
            </VList>
          </td>
          <td class="text-center">
            <VTabs
              centered
              hide-slider
              height="52px"
              active-class="rounded-pill"
              style="width: min-content;"
              :class="`rounded-pill my-2 mx-auto role-${getRoleForCurrentProgram(props.item)}`"
              :value="roles.indexOf(getRoleForCurrentProgram(props.item))"
              @change="(index) => onChangeRole(props.item, index)"
            >
              <VTab>None</VTab>
              <VTab>{{ featureName("Student") }}</VTab>
              <VTab>{{ featureName("Mentor") }}</VTab>
            </VTabs>
          </td>
        </template>
      </DataViewUI>
    </VCol>
  </VRow>
</template>
<script>
import { mapActions } from "vuex";
import SearchMixin from "@/mixins/Search";
export default {
  name: "AddUsers",
  mixins: [SearchMixin],
  props: {
    programId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      programFilter: null,
      enableInfiniteLoader: true,
      roles: ["none", "student", "mentor"],
    };
  },
  computed: {
    headers() {
      return [
        {
          text: "User Info",
          align: "left",
          sortable: false,
          value: "",
        },
        {
          text: "Choose Role",
          align: "center",
          sortable: false,
          value: "",
        },
      ];
    },
  },
  methods: {
    ...mapActions({
      doGetUsers: "users/getAll",
      doSendInviteToProgrm: "team/inviteToProgram",
    }),
    onGetAll(params) {
      params._with = ["programs"];
      if(this.programFilter) {
        params.s = this.$refs.dataViewUI.filter.s;
        params.filter = {
          program: [this.programFilter],
        };
      }
      return this.doGetUsers(params);
    },
    getRoleForCurrentProgram(user) {
      const currentProgram = user.programs.find((program) => program.id === this.programId);
      return currentProgram ? currentProgram.pivot.type : "none";
    },
    onChangeRole(user, roleIndex) {
      user.programs = [
        {
          id: this.programId,
          pivot: {
            type: this.roles[roleIndex],
          },
        }
      ];
      user.program_role = this.roles[roleIndex];
      this.$nextTick(() => {
        const usersWithUpdatedRole = this.infiniteLoad.data.filter((item) => item.hasOwnProperty("program_role") && item.program_role != "none");
        this.$emit("disabled", usersWithUpdatedRole.length === 0);
      });
    },
    onSave() {
      this.$emit("loading", true);
      const usersWithUpdatedRole = this.infiniteLoad.data.filter((item) => item.hasOwnProperty("program_role") && item.program_role != "none");
      const params = {
        program_id: this.programId,
        users: usersWithUpdatedRole.map((user) => {
          return {
            email: user.email,
            role: user.program_role,
          };
        })
      };
      this.doSendInviteToProgrm(params).then((result) => {
        if(result) {
          this.$emit("close");
          this.$emit("loading", false);
          this.$emit("disabled", true);
          this.$bus.$emit("notificationMessage", `Selected users successfully added to ${this.featureName('program', 'singularize')}`);
        }
      });
    },
  },
}
</script>
<style lang="scss">
#add-users {
  .data-view-title {
    border: none;
    padding: 0px;
    margin-bottom: 8px;
    .spacer {
      display: none;
    }
  }
  .programs-filter {
    max-width: 264px;
    .v-input__control > .v-input__slot fieldset {
      border: 1px solid #666666;
      border-radius: 12px;
    }
    .v-label, input {
      font-size: 14px;
    }
    input::placeholder {
      color: #666666;
    }
    .v-input__prepend-inner {
      margin-top: 8px !important;
      padding-right: 12px;
    }
  }
  .search-field {
    max-width: 220px;
    .v-input__control > .v-input__slot {
      border: 1px solid #666666;
      border-radius: 12px;
    }
    .v-label {
      font-size: 14px;
    }
  }
}
</style>