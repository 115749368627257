import BaseApi from "./_base-api";

/**
 * Program API
 *
 * @class Api
 * @extends {BaseApi}
 */
class Api extends BaseApi {
	setUserPermissions(params) {
		return this.api()
			.post(this.getUrl("programs", `${params.program_id}/user/${params.user_id}/permissions`), params)
			.catch(error => {
				this.log().error("[Services: Programs: Set User Permissions]:", error);
				throw this.utils.resolveError(error);
			});
	}
	removeAttachments(params) {
		return this.api()
			.post(this.getUrl("programs", `${params.program_id}/remove-attachments`), params)
			.catch(error => {
				this.log().error("[Services: Programs: Remove associated items of a program]:", error);
				throw this.utils.resolveError(error);
			});
	}
	users(params) {
		return this.api()
			.get(this.getUrl("programs", `${params.program_id}/users`), { params })
			.catch(error => {
				this.log().error("[Services: Programs: Getting program's users]:", error);
				throw this.utils.resolveError(error);
			});
	}
	updateUserRoleForProgram(params) {
		return this.api()
			.post(this.getUrl("programs", `${params.program_id}/users/${params.user_id}`), params)
			.catch(error => {
				this.log().error("[Services: Programs: Error updating user role for a program]:", error);
				throw this.utils.resolveError(error);
			});
	}
	removeUserFromProgram(params) {
		return this.api()
			.delete(this.getUrl("programs", `${params.program_id}/users/${params.user_id}/remove`))
			.catch(error => {
				this.log().error("[Services: Programs: Error removing user from program]:", error);
				throw this.utils.resolveError(error);
			});
	}
}

const api = new Api({
	type: "Program",
	path: "programs"
});

export default api;
