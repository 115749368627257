<template>
  <VRow 
    id="import-people"
    class="mx-0"
  >
    <VCol cols="12">
      <p 
        class="mb-0"
        style="font-size: 18px;"
      >
        Bulk upload email addresses. Drag and drop a file to the box below to get started. Make sure “Email” is the first column. Accepted formats: .csv
      </p>
      <DragDropFileUpload
        ref="fileUpload"
        accept="text/csv"
        :class="{
          'mt-8': true,
          'd-none': hasPreparedData,
        }"
        :error-message="error"
        :is-proccessing="isProcessing"
        @files="handleFile"
      />
    </VCol>
    <VCol 
      v-if="hasPreparedData"
      cols="12"
      class="pb-0"
    >
      <p class="d-none">
        {{ totalInvalidRecords }}
      </p>
      <DataViewUI
        hide-records-info
        hide-item-actions
        hide-main-title
        items-are-local
        id="prepared-users-data"
        :data="prepared"
        :headers="headers"
      >
        <template #items-cells="props">
          <td class="simple">
            {{ props.item.email }}
          </td>
          <td class="text-center">
            <VTabs
              centered
              hide-slider
              height="52px"
              active-class="rounded-pill"
              style="width: min-content;"
              :class="`rounded-pill my-2 mx-auto role-${props.item.role.toLowerCase()}`"
              :value="props.item.role.toLowerCase() == 'mentor' ? 1 : 0"
              @change="(value) => props.item.role = value ? 'mentor' : 'student'"
            >
              <VTab>{{ featureName("Student") }}</VTab>
              <VTab>{{ featureName("Mentor") }}</VTab>
            </VTabs>
          </td>
          <td class="text-center">
            <VBtn
              icon
              outlined
              width="44px"
              height="44px"
              color="#FF2A7A"
              style="border: 2px solid rgb(255, 42, 122);"
              :disabled="props.item.isLoading || activity.isFormLoading"
              :loading="props.item.isLoading"
              @click="prepared.data.splice(props.index, 1)"
            >
              <VIcon 
                small
                style="font-weight: 700;"
              >
                close
              </VIcon>
            </VBtn>
          </td>
        </template>
      </DataViewUI>
    </VCol>
  </VRow>
</template>
<script>
import Papa from "papaparse";
import { mapActions } from "vuex";
import { required, minLength, email } from "vuelidate/lib/validators";
import ImportsData from "@/mixins/ImportsData";
import DragDropFileUpload from "@/components/Elements/Forms/DragDropFileUpload";

export default {
  name: "Import",
  mixins: [ImportsData],
  props: {
    program: {
      type: Object,
      default: null,
    },
  },
  components: {
    DragDropFileUpload,
  },
  data() {
    return {
      isProcessing: false,
    };
  },
  validations: {
    prepared: {
      data: {
        $each: {
          email: {
            required,
            email,
            minLength: minLength(4),
          },
        },
      },
    },
  },
  computed: {
    headers() {
      return [
        {
          text: "Email Address",
          align: "left",
          sortable: false,
          value: "",
        },
        {
          text: "Role",
          align: "center",
          sortable: false,
          value: "",
        },
        {
          text: "Cancel",
          align: "center",
          sortable: false,
          value: "",
        },
      ];
    },
    totalErrors() {
      let errorCount = 0;
      for (const field in this.$v.prepared.data.$each.$iter) {
        errorCount += Number(this.$v.prepared.data.$each.$iter[field].$error === true);
      }
      return errorCount;
    },
    totalInvalidRecords() {
      const allPeopleOfProgram = this.program.mentors.concat(this.program.students);
      const duplicateEmails = this.findDuplicates(this.prepared.data, "email");
      const alreadyExistEmails = this.findDuplicatesInTwoArrays(allPeopleOfProgram, this.prepared.data, "email");
      const totalInvalidRecords = this.totalErrors + duplicateEmails.length + alreadyExistEmails.length;
      this.$log.debug("[Import Users]: Total invalid records", totalInvalidRecords, duplicateEmails, alreadyExistEmails);
      this.$emit("totalInvalidRecords", totalInvalidRecords);
      return totalInvalidRecords;
    },
  },
  methods: {
    ...mapActions({
      doInviteUsers: "team/bulkInviteToProgram",
    }),
    onSave() {
      let data = {
        users: this.prepared.data,
        program_id: this.program.id,
      };
      this.$emit("loading", true);
      return this.onImportData(() =>
        this.saveFormModel(this.doInviteUsers, null, data, null, false).then((result) => {
          this.$emit("close");
          this.$emit("loading", false);
          this.$bus.$emit("notificationMessage", "Users invitations pending. You will receive a notification when your bulk upload is complete");
          return result;
        })
      );
    },
    reset() {
      this.$log.debug("[FormMixin]: Resetting form model");
      this.form = { ...this.defaultForm };
      this.hasFile = false;
      this.$refs.fileUpload.onReset();
      this.saved.data = [];
      this.isProcessing = false;
      this.prepared.data = { ...this.defaultPrepared };
    },
    handleFile(files) {
      this.error = "";
      if(!files.length) {
        return;
      }
      this.isProcessing = true;
      const file = this.onHandleFileStart(files);
      const self = this;
      Papa.parse(file, {
        header: true,
        delimiter: ",",
        skipEmptyLines: true,
        complete(result) {
          if (result.meta.fields.length === 0 || (result.meta.fields.length > 0 && result.meta.fields[0].toLowerCase() != "email")) {
            self.error = "Email must be the first column in the file";
            self.isProcessing = false;
          } else {
            self.onHandleFileComplete(result, () => {
              const callback = function (result) {
                self.$log.debug("[Import Users]: Completed importing file", result);
                if (result.errors && result.errors.length) {
                  self.errors = result.errors.map((error) => error.message);
                  self.isProcessing = false;
                } else {
                  self.message = `Prepared&nbsp;<strong>${result.data.length}</strong>&nbspentries`;
                  self.prepared.headers = result.meta.fields.map((header) => {
                    return {
                      text: header,
                      align: "left",
                      sortable: true,
                      value: header.toLowerCase(),
                      realKey: header.toLowerCase(),
                    };
                  });
                  self.prepared.data = result.data.map((item) => {
                    let normalized = {
                      index: {
                        text: "#",
                        align: "left",
                        sortable: false,
                        value: "index",
                      },
                    };
                    Object.keys(item).map((v, k) => {
                      normalized[v.toLowerCase()] = item[v];
                      if (["email"].indexOf(v.toLowerCase()) > -1) {
                        normalized.email = (item[v] || "").toLowerCase();
                      }
                    });
                    normalized.role = (normalized.role || "Student").toLowerCase();
                    return normalized;
                  });
                  self.prepared.total = self.prepared.data.length;
                }
                self.validateModelAndSetErrors;
                self.isProcessing = false;
                self.$emit("doneLoading");
              };
              callback(result);
            });
          }
        },
      });
    },
  },
}
</script>