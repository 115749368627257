<template>
  <VDialog
    v-if="isDialogOpen"
    scrollable
    :content-class="`${defaultContentClass} ${customClass}`"
    :persistent="persistent || $vuetify.breakpoint.smAndDown"
    :value="isDialogOpen"
    :width="width"
    :max-width="$vuetify.breakpoint.smAndDown ? '100%' : maxWidth"
    :fullscreen="fullscreen || $vuetify.breakpoint.smAndDown"
  >
    <VCard
      :class="allCardClasses"
      :style="{
        minHeight: minHeight
      }"
    >
      <VCardTitle v-if="title.length || dismissable">
        <VRow
          dense
          align-center
          color="transparent"
        >
          <slot name="title">
            <span class="text-truncate text-no-wrap">{{ title }}</span>
          </slot>
          <VSpacer />
          <slot name="actions">
            <VBtn
              v-if="dismissable"
              icon
              @click.native="$emit('toggle:dialog')"
            >
              <VIcon color="error">
                close
              </VIcon>
            </VBtn>
          </slot>
        </VRow>
      </VCardTitle>
      <VCardText :class="allCardTextClasses">
        <slot :uid="uid" />
      </VCardText>
      <VCardActions
        v-if="hasAction"
        :style="{
          ...actionStyle,
          'align-items': 'flex-end',
        }"
      >
        <VCol 
          v-if="enableCancelButton"
          align-start
        >
          <slot name="footer-left">
            <VBtn
              text
              :disabled="isLoading || disabled"
              @click.native="$emit('toggle:dialog')"
            >
              {{ cancelText }}
            </VBtn>
          </slot>
        </VCol>
        <VCol
          grow
          align-center
        >
          <slot name="footer-center" />
        </VCol>
        <VCol
          v-if="enableSaveButton"
          shrink
          align-end
        >
          <slot name="footer-right">
            <VBtn
              class="wethrive"
              :disabled="isLoading || disabled"
              :loading="isLoading"
              @click="handleAction"
            >
              {{ buttonText }}
            </VBtn>
          </slot>
        </VCol>
      </VCardActions>
    </VCard>
  </VDialog>
</template>

<script>
export default {
  name: "CustomDialog",
  props: {
    dismissable: {
      type: Boolean,
      default: true,
    },
    isDialogOpen: {
      type: Boolean,
      default: false,
    },
    persistent: {
      type: Boolean,
      default: true,
    },
    fullscreen: {
      type: Boolean,
      default: false,
    },
    hideOverlay: {
      type: Boolean,
      default: false,
    },
    fillHeight: {
      type: Boolean,
      default: false,
    },
    minHeight: {
      type: String,
      default: "auto",
    },
    column: {
      type: Boolean,
      default: false,
    },
    row: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "",
    },
    action: {
      type: Function,
      default: null,
    },
    maxWidth: {
      type: String,
      default: "500px",
    },
    width: {
      type: String,
      default: "100%",
    },
    buttonText: {
      type: String,
      default: "Save",
    },
    cancelText: {
      type: String,
      default: "Cancel",
    },
    closeImmediately: {
      type: Boolean,
      default: false,
    },
    enableFooterSlots: {
      type: Boolean,
      default: false,
    },
    cardElevation: {
      type: [Number, String],
      default: 0,
    },
    cardClass: {
      type: [Object, String],
      default: "",
    },
    cardTextClass: {
      type: [Object, String],
      default: "",
    },
    customDialogData: {
      type: Array,
      default: null,
    },
    enableCancelButton: {
      type: Boolean,
      default: true,
    },
    enableSaveButton: {
      type: Boolean,
      default: true,
    },
    customClass: {
      type: String,
      default: null,
    },
    defaultContentClass: {
      type: String,
      default: "custom-dialog",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    actionStyle: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      isLoading: false,
      /**
       * An object with two keys: uid and data. All data sent must be encapculated by an array
       * Correct: {
       *    uid: Number|String,
       *    data: [[0, 1, 2]] OR [{test: 0}]
       * }
       * Wrong: [0, 1, 1], {test: 0}
       * Not encapculating the data will result in unpredicable behavior
       */
      dialogData: null,
    };
  },
  computed: {
    hasAction() {
      return this.action instanceof Function || this.enableFooterSlots;
    },
    allCardClasses() {
      let cardClass = {};
      if (this.cardClass instanceof Object) {
        cardClass = { ...cardClass, ...this.cardClass };
      } else {
        cardClass[this.cardClass] = true;
      }
      return {
        ...{
          [`elevation-${this.cardElevation}`]: true,
        },
        ...cardClass,
      };
    },
    allCardTextClasses() {
      let cardClass = {
        "py-0": true,
        "fill-height": this.fillHeight,
        "align-center": this.fillHeight,
        "justify-center": this.fillHeight,
        "d-flex": this.fillHeight,
        column: this.column,
        row: this.row,
      };
      if (this.cardTextClass instanceof Object) {
        cardClass = { ...cardClass, ...this.cardTextClass };
      } else {
        cardClass[this.cardTextClass] = true;
      }
      return cardClass;
    },
  },
  created() {
    this.$bus.$on("saveDialogData", (data) => {
      if (this.uid === data.uid) {
        this.dialogData = data.data;
      }
    });
    this.$bus.$on("toggle:dialog", (data) => {
      if (this.uid === data.uid) {
        this.$emit("toggle:dialog");
      }
    });
    if (this.customDialogData && this.customDialogData.length > 0) {
      this.dialogData = this.customDialogData;
    }
    // this.toggleIntercom();
  },
  destroyed() {
    // this.toggleIntercom(true);
  },
  watch: {
    customDialogData: {
      handler(value) {
        this.dialogData = value;
      },
      deep: true,
    },
  },
  methods: {
    /**
     * this.dialogData is expected to be an array of arguments for the handler.apply() function
     */
    handleAction() {
      this.isLoading = true;
      let promise = this.action.apply(null, this.dialogData);
      promise = promise instanceof Promise ? promise : Promise.resolve(promise);
      promise.then((result) => {
        this.isLoading = false;
        if (this.closeImmediately) {
          this.$emit("toggle:dialog");
        }
      });
    },
    toggleIntercom(show) {
      const container = document.querySelector("#intercom-container");
      if (container instanceof HTMLElement) {
        container.style.zIndex = show === true ? "2147483647" : "-1";
      }
    },
  },
};
</script>

<style lang="scss">
.custom-dialog {
  overflow: visible;
  .v-card .v-card__title {
    height: 72px;
    flex: 0 0 72px;
    border-radius: 6px 6px 0 0;
    // background-color: #fafafa;
    .v-toolbar__content {
      height: 18px;
      color: #333636;
      font-size: 18px;
      font-weight: 500;
      line-height: 22px;
      text-transform: none;
      padding-left: 0px;
    }
  }
}
.custom-dialog
  .custom-dialog
  .v-card
  .v-card__title
  .custom-dialog
  .v-dialog--active
  .v-dialog--scrollable {
  overflow: visible;
}
</style>