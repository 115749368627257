<template>
  <VRow>
    <VCol cols="12">
      <VSelect
        v-model="form.currentRole"
        dense
        outlined
        hide-details
        label="Select Role"
        :items="validRoles($team.roles, ['organization-admin'])"
      />
    </VCol>
  </VRow>
</template>

<script>
import { mapActions } from "vuex";
import FormMixin from "@/mixins/Form";
export default {
  name: "UpdateUserRole",
  mixins: [FormMixin],
  props: {
    programId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      skipFormValidation: true,
      defaultForm: {
        currentRole: null,
      },
    };
  },
  mounted() {
    this.form.currentRole = this.form.attachment_meta.type;
  },
  methods: {
    ...mapActions({
      doUpdateUserRoleForProgram: "program/updateUserRole",
    }),
    onSave() {
      const params = {
        program_id: this.programId,
        user_id: this.form.id,
        role: this.form.currentRole,
      };
      return this.saveFormModel(this.doUpdateUserRoleForProgram, null, params, null, false).then((result) => {
        if(result) {
          this.form.attachment_meta.type = this.form.currentRole;
          this.$bus.$emit("notificationMessage", "Role updated successfully!");
          this.$emit("toggle:form:dialog");
        }
      });
    },
  },
};
</script>
