<script>
import { mapActions } from "vuex";
import HasPagination from "@/mixins/HasPagination";
import ActivityMixin from "@/mixins/Activity";
import FormMixin from "@/mixins/Form";

export default {
  mixins: [ActivityMixin, FormMixin, HasPagination],
  data() {
    return {
      useIndexes: false,
      hasFile: false,
      hasOptions: false,
      uniqueKey: null,
      metadataHeaders: [],
      defaultHeaders: [
        {
          text: "Unique Value",
          align: "left",
          sortable: false,
          value: "uniqueKey",
        },
      ],
      saved: {
        headers: [],
        data: [],
        total: 0,
        per_page: 10,
        current_page: 1,
        last_page: 1,
      },
      savedPaginated: {},
      prepared: {
        headers: [],
        data: [],
        total: 0,
        per_page: 10,
        current_page: 1,
        last_page: 1,
      },
      preparedPaginated: {},
      defaultPrepared: {
        headers: [],
        data: [],
        total: 0,
        per_page: 10,
        current_page: 1,
        last_page: 1,
      },
      hasPrepared: false,
      error: "",
      message: "No data provided. Please upload a CSV file",
    };
  },
  validations: {},
  computed: {
    allHeaders() {
      return [...this.defaultHeaders, ...this.prepared.headers];
    },
    visibleHeaders() {
      return [
        ...this.defaultHeaders,
        ...this.prepared.headers.filter((h) => h.hidden !== true),
      ];
    },
    hasErrorsOrMessages() {
      return this.error.length > 0 || this.message.length > 0;
    },
    isSavedDataQueued() {
      return this.saved.data.isQueued === true;
    },
    hasPreparedData() {
      this.$emit("dataPrepared", this.prepared.data.length > 0);
      return this.prepared.data.length > 0;
    },
    hasSavedData() {
      return this.saved.data.length > 0;
    },
    isReadyToImport() {
      return !this.hasSavedData && this.hasPreparedData;
    },
  },
  methods: {
    onImportData(callback) {
      this.$v.$touch();
      this.validateModelAndSetErrors();
      if (!this.$v.$invalid) {
        return callback()
          .then((result) => {
            this.saved.data = result;
            this.prepared.data = [];
            this.prepared.total = 0;
            // this.onPaginateSavedLocal();
            return result;
          })
          .catch((error) => {
            if (error.code === 422) {
              this.saved.data = error.message;
              this.errors = [
                `There were errors saving data. Please review the results below`,
              ];
            }
          });
      }
      return Promise.reject("Import data is not ready");
    },
    onHandleFileStart(files) {
      const file = files[0];
      if (!file) {
        throw new Exception("No file provided");
      }
      this.onReset(true, false);
      this.$emit("startLoading");
      this.$log.debug("[Import Data]: Importing file", file, files);
      this.hasFile = true;
      this.saved.data = [];
      this.prepared.data = { ...this.defaultPrepared };
      this.errors = this.messages = [];
      this.error = this.message = "";
      return file;
    },
    onHandleFileComplete(result, handler) {
      this.$log.debug("[Import Data]: Completed importing file", result);
      if (result.errors && result.errors.length) {
        this.errors = result.errors.map((error) => error.message);
      } else {
        this.hasPrepared = true;
        this.message = `Prepared&nbsp;<strong>${result.data.length}</strong>&nbspentries`;
        this.metadataHeaders = result.meta.fields.map((header) => {
          return {
            id: header,
            text: header,
          };
        });
        this.uniqueKey = this.metadataHeaders[0];

        // Call the handler syncrhonously to handler the prepared data
        handler();
        this.validateModelAndSetErrors();
        if (this.useIndexes) {
          this.prepared.headers.unshift({
            text: "#",
            align: "left",
            sortable: false,
            value: "index",
          });
        }
        this.prepared.total = this.prepared.data.length;
        // this.onPaginatePreparedLocal();
      }
      this.$v.$touch();
      this.$emit("doneLoading");
    },
    onSearch(s) {
      return this.saved.data.filter((user) => {
        if (this.filter && this.filter.length) {
          const nameMatch = user.name.match(this.filter);
          const emailMatch = user.email.match(this.filter);
          return (
            (nameMatch && nameMatch.length > 0) ||
            (emailMatch && emailMatch.length > 0)
          );
        }
        return true;
      });
    },
    onReset(force, resetSelected) {
      this.hasFile = false;
      const resetFunc = (result, shouldResetEntities) => {
        if (result === true) {
          this.hasPrepared = false;
          this.error = this.message = "";
          this.prepared = this.preparedPaginated = {
            ...{},
            ...this.defaultPrepared,
          };
          this.errors =
            this.messages =
            this.metadataHeaders =
            this.saved.data =
              [];
          this.savedPaginated = {};
          if (shouldResetEntities) {
            this.selectedDeliverableId =
              this.selectedCalendarEntryId =
              this.selectedEntityType =
              this.selectedEntityId =
              this.uniqueKey =
                null;
            this.$refs.fileUpload.onReset();
          }
        }
      };

      if (force === true) {
        resetFunc(true, resetSelected);
      } else {
        this.$refs["confirmReset"]
          .open("Reset", "Are you sure?", {
            color: "orange",
          })
          .then((result) => {
            resetFunc(result, true);
          });
      }
    },
    getUniqueKeyValue(index) {
      let item = index instanceof Object ? index : this.prepared.data[index];
      let metadata;
      let value = "None";
      const key =
        this.uniqueKey instanceof Object
          ? this.uniqueKey.id.toLowerCase()
          : this.uniqueKey.toLowerCase();
      if (item) {
        metadata = item.metadata.find(
          (item) => item.name.toLowerCase() == key.toLowerCase()
        );
        if (metadata) {
          value = metadata.value || "None";
        } else {
          value = item[key] || item[key.toLowerCase()] || "None";
        }
      }
      return value;
    },
    getUniqueKeyValueFromValidation(validation) {
      const key =
        this.uniqueKey instanceof Object
          ? this.uniqueKey.id.toLowerCase()
          : this.uniqueKey;
      return validation.$model[key] || key;
    },
    /**
     * Get the validation errors
     */
    getValidationErrors() {
      return Object.entries(this.$v.prepared.data.$each.$iter)
        .filter(
          (object) =>
            ["$invalid", "$dirty", "$error", "$pending", "$params"].indexOf(
              object[0]
            ) === -1
        )
        .map((entry, index) => {
          let key, validation;
          [key, validation] = entry;
          key = key.charAt(0).toUpperCase() + key.slice(1);

          if (validation.$invalid || validation.$error) {
            let message = Object.get(
              this.validationMessages.errors,
              key.toLowerCase()
            );
            this.$log.debug(
              "[ImportsData: Form Validation Errors]:",
              this.uniqueKey,
              validation,
              key.toLowerCase(),
              { ...this.validationMessages.errors },
              message
            );
            const uniqueKey = this.uniqueKey
              ? this.getUniqueKeyValueFromValidation(validation)
              : key;
            const invalidKeys = this.prepared.headers
              .filter((h) => {
                const field = validation[h.realKey || h.value];
                if (field instanceof Object) {
                  field.$touch();
                  return field.$invalid === true;
                }
                return false;
              })
              .map((h) => h.text);
            const realKey = parseInt(index) + 1;
            if (message) {
              return message instanceof Function
                ? message(validation.$params)
                : message;
            } else if (validation.$params.required) {
              return `# ${realKey} - <b>${uniqueKey}</b> is <b>required</b>`;
            } else if (validation.$params.minLength && validation.$invalid) {
              return `# ${realKey} - <b>${uniqueKey}</b> has a minimum length of <b>${validation.$params.minLength.min}</b>`;
            } else if (validation.$params.maxLength && validation.$invalid) {
              return `# ${realKey} - <b>${uniqueKey}</b> has a maximum length of <b>${validation.$params.maxLength.max}</b>`;
            } else if (validation.$invalid) {
              return `# ${realKey} - <b>${uniqueKey}</b> has invalid data. Please check <b>${invalidKeys.join(
                ", "
              )} </b>`;
            } else if (validation.$error) {
              return `# ${realKey} - <b>${uniqueKey}</b> has invalid data. Please check <b>${invalidKeys.join(
                ", "
              )}</b>`;
            } else {
              return null;
            }
          } else {
            return null;
          }
        })
        .filter((val) => val !== null);
    },
    onPaginatePreparedLocal(pagination) {
      this.preparedPaginated = {
        ...this.preparedPaginated,
        ...this.getLocalDataPaginated(pagination, "prepared"),
      };
    },
    onPaginateSavedLocal(pagination) {
      this.savedPaginated = {
        ...this.savedPaginated,
        ...this.getLocalDataPaginated(pagination, "saved"),
      };
    },
    onUpdatePaginationPreparedLocal(pagination) {
      this.onPaginateLocal(pagination, "preparedPaginated");
      this.onPaginatePreparedLocal(pagination);
    },
    onUpdatePaginationSavedLocal(pagination) {
      this.onPaginateLocal(pagination, "savedPaginated");
      this.onPaginateSavedLocal(pagination);
    },
  },
};
</script>