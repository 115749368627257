<template>
  <VRow>
    <VCol cols="12">
      <VRow
        v-for="(section, index) in allSections"
        :key="index"
      >
        <VCol 
          :class="{
            'd-flex align-center py-4': true,
            'mt-n6': index === 0,
          }"
        >
          <span class="label-text text-truncate">
            {{ section.title }}
          </span>
          <VSpacer />
          <template v-if="['huddles'].indexOf(section.name) === -1">
            <TextField
              v-if="section.name != 'people' && hasSectionItems(section.name)"
              single-line
              hide-details
              height="44px"
              append-icon="refresh"
              label="Search..."
              class="search-field shrink"
              :ref="`${section.name}-search-input`"
              :disabled="loadingSection == section.name"
              :loading="loadingSection == section.name"
              @keyup.enter="searchDashboardSectionItems(section)"
              @click:append="searchDashboardSectionItems(section)"
            />
            <VBtn
              v-if="isAddNewEnable(section)"
              outlined
              height="44px"
              color="#0A5894"
              class="no-shadow px-4 ml-4 mr-1"
              style="border-radius: 9px; background-color: #FFFFFF;"
              @click="$emit('addSection', section)"
            >
              <VIcon color="#0A5894">
                add
              </VIcon>
              <span class="text-caption font-weight-bold pl-1">
                Add New
              </span> 
            </VBtn>
            <ItemActions
              v-if="section.name != 'people'"
              is-for-dashboard
              class="ml-3"
              :section="section"
              :model="model"
              :offset-y="section.name == 'faqs'"
              @addSection="$emit('addSection', section)"
              @reorder="$emit('showReorderDialog', section.name)"
              @toggleTab="$emit('toggleTab', section.name)"
              @toggleAttach="$emit('showAttachDialog', section.name)"
              @renameFeature="$emit('showRenameDialog', section.name)"
            />
          </template>
        </VCol>
        <VCol cols="12">
          <component
            v-if="hideSections.indexOf(section.name) === -1"
            :is="section.name"
            :ref="section.name + '-dashboard-ref'"
            :model="model"
            :sections="sections"
            :is-dashboard="isDashboard"
            :is-tiles-view="isTilesView"
            :sort-by="sortBy"
            @showViewDialog="
              (sectionName, item) => $emit('showViewDialog', sectionName, item)
            "
            @toggleTab="(tab) => $emit('toggleTab', tab)"
            @addSection="$emit('addSection', section)"
            @toggleAttach="$emit('showAttachDialog', section.name)"
            @searchComplete="onSearchComplete"
          />
        </VCol>
      </VRow>
    </VCol>
  </VRow>
</template>

<script>
import ViewProgramSection from "@/mixins/ViewProgramSection";
import People from "./People/index";
import Lessons from "./Lessons";
import Huddles from "./Huddles";
import Contents from "./Content";
import Deliverables from "./Deliverables";
import Pipelines from "./Pipelines";
import Faqs from "./FAQ";
import ItemActions from "./Actions";

export default {
  name: "Dashboard",
  mixins: [ViewProgramSection],
  components: {
    People,
    Huddles,
    Lessons,
    Contents,
    Deliverables,
    Pipelines,
    Faqs,
    ItemActions,
  },
  data() {
    return {
      hideSections: ["dashboard", "permissions"],
      loadingSection: null,
    };
  },
  computed: {
    allSections() {
      return this.sections.filter(
        (section) => this.hideSections.indexOf(section.name) === -1
      );
    },
  },
  methods: {
    searchDashboardSectionItems(section) {
      this.loadingSection = section.name;
      const currentSectionRef = this.$refs[section.name + "-dashboard-ref"][0];
      const searchString = this.$refs[section.name + "-search-input"][0].$refs.input.value;
      currentSectionRef.filter.s = searchString;
      currentSectionRef.isLoading = true;
      currentSectionRef.initSearch();
    },
    onSearchComplete() {
      const currentSectionRef = this.$refs[this.loadingSection + "-dashboard-ref"][0];
      currentSectionRef.isLoading = false;
      this.loadingSection = null;
    },
    hasSectionItems(sectionName) {
      return this.model[sectionName == "contents" ? "primary_contents" : sectionName].length > 0;
    },
    isAddNewEnable(section) {
      const sectionName = section.name == "people" ? "invite" : section.name.singularize();
      if((this.$isUserAdmin && this.$viewProgramAsAdmin) || this.checkMentorPermission(sectionName, this.model)) {
        return section.name == 'people' || (section.name != 'people' && this.hasSectionItems(section.name));
      }
      return false;
    },
  },
};
</script>
