import api from '../../services/program';
export default function (helper) {
  /**
   * Generated store module
   */
  helper.setApi(api);
  const types = helper.types('program');
  const state = helper.state({
    viewAs: "organization-admin",
    sectionsVisibility: {
      people: true,
      huddles: true,
      lessons: true,
      content: true,
      deliverables: true,
      faqs: true,
    },
  });
  const getters = helper.getters();
  const actions = helper.actions({
    setUserPermissions(context, params, programId, userId) {
      helper
        .log()
        .info(`[Store: Program}]: Set user permissions for a program`, params);
      return new Promise((resolve, reject) => {
        return helper
          .api()
          .setUserPermissions(params)
          .then((response) => {
            helper
              .log()
              .info(`[Store: Program]: Set user permissions for a program`, response.data);
            resolve(response.data.data);
          })
          .catch((error) => {
            helper
              .log()
              .info(`[Store: Program]: Error Setting user permissions for a program`, error);
            reject(error);
          })
      });
    },
    removeAssociatedItems(context, params) {
      helper
        .log()
        .info(`[Store: Program}]: Remove associated items of a program`, params);
      return new Promise((resolve, reject) => {
        return helper
          .api()
          .removeAttachments(params)
          .then((response) => {
            helper
              .log()
              .info(`[Store: Program]: Removed associated items of a program`, response.data);
            resolve(response.data.data);
          })
          .catch((error) => {
            helper
              .log()
              .info(`[Store: Program]: Error remove associated items of a program`, error);
            reject(error);
          })
      });
    },
    users(context, params) {
      helper
        .log()
        .info(`[Store: Program}]: Get program's users`, params);
      return new Promise((resolve, reject) => {
        return helper
          .api()
          .users(params)
          .then((response) => {
            helper
              .log()
              .info(`[Store: Program]: Got program's users`, response.data);
            resolve(response.data);
          })
          .catch((error) => {
            helper
              .log()
              .info(`[Store: Program]: Error getting program's users`, error);
            reject(error);
          })
      });
    },
    updateUserRole(context, params) {
      helper
        .log()
        .info(`[Store: Program}]: Update user role for a program`, params.data);
      return new Promise((resolve, reject) => {
        return helper
          .api()
          .updateUserRoleForProgram(params.data)
          .then((response) => {
            helper
              .log()
              .info(`[Store: Program]: Updated user role for a program`, response.data);
            resolve(response.data);
          })
          .catch((error) => {
            helper
              .log()
              .info(`[Store: Program]: Error updating user role for a program`, error);
            reject(error);
          })
      });
    },
    removeUser(context, params) {
      helper
        .log()
        .info(`[Store: Program}]: Remove user from program`, params);
      return new Promise((resolve, reject) => {
        return helper
          .api()
          .removeUserFromProgram(params)
          .then((response) => {
            helper
              .log()
              .info(`[Store: Program]: Removed user from program`, response.data);
            resolve(response.data);
          })
          .catch((error) => {
            helper
              .log()
              .info(`[Store: Program]: Error removing user from program`, error);
            reject(error);
          })
      });
    },
  }, 'Program');
  const mutations = helper.mutations({
    updateViewAs(state, data) {
      return state.viewAs = data;
    },
    updateSectionsVisibility(state, data) {
      return state.sectionsVisibility[Object.keys(data)[0]] = Object.values(data)[0];
    },
  }, types, state);

  return {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
  };
}