<template>
  <div>
    <VCard 
      color="#FAF9FF"
      height="110px"
      width="720px"
      style="font-size: 15px; border-radius: 10px;"
      :class="{ 
        'd-flex align-center justify-center': true,
        'dragging': isDragging,
        'processing': isProccessing,
      }"
      @dragover.prevent="onDragover" 
      @dragleave.prevent="onDragleave" 
      @drop.prevent="onDrop"
      @click="triggerFileInput"
    >
      <VFileInput
        single-line
        hide-input
        ref="fileInput"
        class="d-none"
        :accept="accept"
        @change="onFileChange"
      />
      <div
        v-if="isProccessing"
        class="ma-4 d-flex justify-center align-center"
        :style="{
          background: `url(${ProgressImage})`,
          backgroundSize: '100% 100%',
          backgroundRepeat: 'no-repeat',
          height: '42px',
          width: '480px',
        }"
      >
        <h3 class="text-body-2 font-weight-bold">
          Your file is being uploaded...
        </h3>
      </div>
      <template v-else>
        <img 
          src="@/images/spreadsheet-icon.png"
          class="mr-1"
        >
        Drag and drop your CSV here or click to select file
      </template>
    </VCard>
    <p 
      v-if="invalidFileType || errorMessage"
      class="d-flex align-center error--text mt-4"
    >
      <VIcon 
        color="error"
        class="mr-2"
      >
        mdi-information
      </VIcon>
      <span style="font-size: 15px;">
        {{ errorMessage || "Invalid file type. Please upload a valid file type." }}
      </span>
    </p>
  </div>
</template>

<script>
import ProgressImage from "@/images/progress-bar.gif";
export default {
  props: {
    value: {
      type: [Array, String],
      default: null,
    },
    accept: {
      type: String,
      default: "*",
    },
    isProccessing: {
      type: Boolean,
      default: false,
    },
    errorMessage: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      ProgressImage,
      isDragging: false,
      invalidFileType: false,
    };
  },
  methods: {
    triggerFileInput() {
      if(!this.isProccessing) {
        this.$refs.fileInput.$refs.input.click();
      }
    },
    onDragover() {
      this.isDragging = !this.isProccessing && true;
    },
    onDragleave() {
      this.isDragging = !this.isProccessing && false;
    },
    onDrop($event) {
      if(!this.isProccessing) {
        this.isDragging = false;
        this.onFileChange($event);
      }
    },
    getFormData(files) {
      const data = new FormData();
      [...files].forEach((file) => {
        data.append("data", file, file.name);
      });
      return data;
    },
    onFileChange($event) {
      const files =
        $event instanceof File
          ? [$event]
          : $event instanceof Array
          ? $event
          : $event.target.files || $event.dataTransfer.files;
      if(this.isValidFileTypes(files)) {
        const form = this.createFormData ? this.getFormData(files) : files;
        if(files) {
          if(files.length > 0) {
            this.filename = [...files].map((file) => file.name).join(", ");
            this.$log.debug("FileUpload: Added file", this.filename);
          }
        } else {
          this.filename = $event.target.value.split("\\").pop();
        }
        this.$log.debug("DragDrop FileUpload: File change", files, this.filename, form);
        this.$emit("files", files);
        this.$emit("input", this.filename);
        this.$emit("formData", form[0]);
      }
    },
    isValidFileTypes(files) {
      if(this.accept === "*") {
        return true;
      }
      const acceptedTypes = this.accept.split(",").map((type) => type.trim());
      const fileTypes = [...files].map((file) => file.type.trim());
      const isValid = fileTypes.every((type) => acceptedTypes.includes(type));
      if(!isValid) {
        this.$log.debug("DragDrop FileUpload: Invalid file type", fileTypes, acceptedTypes);
        this.onReset();
      }
      this.invalidFileType = !isValid;
      return isValid;
    },
    onReset() {
      this.filename = "";
      this.localFiles = [];
      this.$emit("input", "");
      this.$emit("files", []);
      this.$refs.fileInput.reset();
      this.$refs.fileInput.$refs.input.value = null;
    },
  },
};
</script>

<style scoped>
.v-card--link:focus:before {
  opacity: 0;
}
.processing {
  cursor: progress;
}
.dragging {
  border: 1px dashed #000;
  border-color: #000 !important;
}
</style>