<template>
  <VRow 
    column
    class="people-section people-permissions"
  >
    <VCol cols="12">
      <DataViewUI
        hide-actions
        hide-records-info
        hide-item-actions
        enable-infinite-loader
        search-prepend-icon=""
        search-label="Search..."
        search-append-icon="search"
        :data="infiniteLoad"
        :headers="headers"
        :is-loading="activity.isLoading"
        :filters="allFilters"
        :title="`${featureName('Mentor')} Permissions`"
        @search="onSearch"
        @paginate="onGetMore"
        @infiniteLoad="onInfiniteLoad"
      >
        <template #title>
          <span 
            class="label-text text-truncate"
            style="font-size: 24px;"
          >
            {{ featureName('Mentor') }} Permissions
          </span>
        </template>
        <template #items-cells="props">
          <td>
            <VList>
              <VListItem class="px-0">
                <VListItemAvatar 
                  size="36px"
                  style="border: 1px solid #0A5894;"
                >
                  <img :src="props.item.photo_url">
                </VListItemAvatar>
                <VListItemContent>
                  <VListItemTitle class="text-subtitle-2">
                    {{ props.item.name }}
                  </VListItemTitle>
                  <VListItemSubtitle 
                    class="text-caption font-weight-medium"
                    style="color: #6E6E72;"
                  >
                    {{ props.item.email }}
                  </VListItemSubtitle>
                </VListItemContent>
              </VListItem>
            </VList>
          </td>
          <td>
            <VSwitch 
              v-model="props.item.permissions.mentor_can_invite_user"
              inset
              color="#0A5894"
              :loading="props.item.isLoadingInvite"
              @change="() => updatePermissions(props.item, props.index, 'invite')"
            />
          </td>
          <td>
            <VSwitch 
              v-model="props.item.permissions.mentor_can_create_content"
              inset
              color="#0A5894"
              :loading="props.item.isLoadingContent"
              @change="() => updatePermissions(props.item, props.index, 'content')"
            />
          </td>
          <td>
            <VSwitch 
              v-model="props.item.permissions.mentor_can_create_deliverable"
              inset 
              color="#0A5894"
              :loading="props.item.isLoadingDeliverable"
              @change="() => updatePermissions(props.item, props.index, 'deliverable')"
            />
          </td>
          <td>
            <VSwitch 
              v-model="props.item.permissions.mentor_can_create_faq"
              inset 
              color="#0A5894"
              :loading="props.item.isLoadingFaq"
              @change="() => updatePermissions(props.item, props.index, 'faq')"
            />
          </td>
          <td>
            <VSwitch 
              v-model="props.item.permissions.mentor_can_create_lesson"
              inset 
              color="#0A5894"
              :loading="props.item.isLoadingLesson"
              @change="() => updatePermissions(props.item, props.index, 'lesson')"
            />
          </td>
        </template>
        <template #no-data>
          <div style="color: inherit;">
            <router-link 
              class="text-caption font-weight-medium text-decoration-underline d-inline-block black--text"
              :to="{
                name: 'program.view',
                hash: '#people-invitations',
                params: {
                  id: model.id,
                  team: $team.slug,
                }
              }"
            >
              There are no {{ featureName("Mentors") }} in this {{ featureName("Program", "singularize") }}. Click here to add People.
            </router-link>
            <VIcon 
              size="18px"
              class="pl-1 black--text"
            >
              mdi-account-plus
            </VIcon>
          </div>
        </template>
      </DataViewUI>
      <Confirm ref="confirmPermission" />
    </VCol>
  </VRow>
</template>

<script>
import { mapActions } from "vuex";
import ViewProgramSection from "@/mixins/ViewProgramSection";

export default {
  name: "Permissions",
  mixins: [ViewProgramSection],
  data() {
    return {
      modelType: "users",
      getAllOnLoad: true,
      enableInfiniteLoader: true,
    };
  },
  computed: {
    headers() {
      return [
        {
          text: "Name",
          align: "left",
          sortable: true,
          value: "name",
        },
        {
          text: `Invite ${this.featureName('User').singularize()}`,
          align: "left",
          sortable: false,
          value: "",
        },
        {
          text: `Create ${this.featureName('Content').singularize()}`,
          align: "left",
          sortable: false,
          value: "",
        },
        {
          text: `Create ${this.featureName('Deliverable').singularize()}`,
          align: "left",
          sortable: true,
          value: "",
        },
        {
          text: `Create ${this.featureName('FAQ').singularize()}`,
          align: "left",
          sortable: false,
          value: "",
        },
        {
          text: `Create ${this.featureName('Lesson').singularize()}`,
          align: "left",
          sortable: false,
          value: "",
        },
      ];
    },
  },
  methods: {
    ...mapActions({
      doGetProgramUsers: "program/users",
      setUserPermissions: "program/setUserPermissions"
    }),
    onGetAll(params) {
      params.roles = ["mentor"];
      params.program_id = this.model.id;
      return this.doGetProgramUsers(params).then((result) => {
        result.data = result.data.map((user) => {
          user.permissions = {
            ...this.teamSettingsForMentor(),
            ...user.attachment_meta.permissions,
          };
          return user;
        });
        return result;
      });
    },
    updatePermissions(user, index, feature) {
      this.$refs.confirmPermission
        .open("Update Permission", `Are you sure to update the permission for ${user.name}?`, {
          color: "info",
        })
        .then((result) => {
          if(result) {
            let params = {
              user_id: user.id,
              program_id: this.model.id,
              permissions: this.getUpdatedPermissions(user, feature),
            };
            this.$set(this.infiniteLoad.data[index], [`isLoading${feature.capitalize()}`], true);
            this.setUserPermissions(params).then((result) => {
              user.attachment_meta.permissions = JSON.parse(result.permissions);
              this.$set(this.infiniteLoad.data[index], [`isLoading${feature.capitalize()}`], false);
              this.$bus.$emit("notificationMessage", "Permission updated successfully");
            });
          } else {
            if(feature == "invite") {
              this.$set(user.permissions, `mentor_can_invite_user`, !user.permissions.mentor_can_invite_user);
            } else {
              this.$set(user.permissions, `mentor_can_create_${feature}`, !user.permissions[`mentor_can_create_${feature}`]);
            }
            this.$set(this.infiniteLoad.data, index, user);
          }
        });
    },
    getUpdatedPermissions(user, feature) {
      const currentPermissions = user.attachment_meta.permissions;
      const updatedPermission = {
        [feature == "invite" ? `mentor_can_invite_user` :  `mentor_can_create_${feature}`]: feature == "invite" ? user.permissions.mentor_can_invite_user : user.permissions[`mentor_can_create_${feature}`]
      };
      return {
        ...currentPermissions,
        ...updatedPermission,
      };
    },
  },
};
</script>
<style lang="scss">
.people-permissions {
  .v-input.v-input--switch--inset .v-input--switch__track:after {
    content: "OFF";
    color: #FFFFFF;
    font-size: 12px;
    justify-content: flex-end;
    display: flex;
    padding-right: 12px;
    padding-left: 6px;
    padding-top: 6px;
  }
  .v-input.v-input--switch--inset.v-input--is-label-active.v-input--is-dirty .v-input--switch__track:after{
    content: "ON";
    color: #FFFFFF;
    justify-content: flex-start;
    display: flex;
    padding-right: 6px;
    padding-left: 12px;
    padding-top: 6px;
  }
  .v-input--switch--inset .v-input--switch__track {
    height: 28px !important;
    width: 64px;
    opacity: 0.6;
  }
  .v-input--switch--inset.v-input--is-dirty .v-input--switch__thumb {
    margin-left: 6px;
    transform: translate(28px, 0) !important;
  }
  .v-input--switch .v-input--selection-controls__ripple, .v-input--switch .v-input--switch__thumb {
    height: 20px !important;
    width: 20px !important;
  }
}
</style>