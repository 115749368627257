var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.isDialogOpen)?_c('VDialog',{attrs:{"scrollable":"","content-class":`custom-form-dialog ${_vm.customClass}`,"max-width":_vm.$vuetify.breakpoint.smAndDown ? '100%' : _vm.maxWidth,"fullscreen":_vm.fullscreen || _vm.$vuetify.breakpoint.smAndDown,"persistent":true,"value":true,"width":_vm.width}},[_vm._t("above-card"),_c('VCard',{class:{
      'form-dialog': true,
      'no-shadow': _vm.transparent,
    },attrs:{"color":_vm.transparent ? 'transparent' : _vm.backgroundColor}},[(!_vm.hideTitle)?_c('VCardTitle',{class:{
        [_vm.cardTitleClass]: true,
        'pb-0': true,
        'border-none': _vm.hideTitleBorder,
      },staticStyle:{"flex":"0 1 auto"}},[_c('VRow',{staticClass:"align-center",attrs:{"dense":"","color":"transparent"}},[(!_vm.readonly && _vm.currentModel && _vm.currentModel.id && _vm.canDelete)?_c('VBtn',{staticClass:"px-1",attrs:{"text":"","color":"error","icon":_vm.$vuetify.breakpoint.smAndDown},nativeOn:{"click":function($event){_vm.$emit('deleteItem', [_vm.currentModel, () => _vm.$emit('toggle:form:dialog')])}}},[_c('VIcon',[_vm._v("delete")]),(_vm.$vuetify.breakpoint.mdAndUp)?[_vm._v(" Delete ")]:_vm._e()],2):_vm._e(),_vm._t("before-title",null,{"props":{
            isFormLoading: _vm.isFormLoading,
            isFormReady: _vm.isFormReady,
          }}),_vm._t("title",function(){return [_c('span',{staticClass:"text-truncate text-no-wrap"},[_vm._v(_vm._s(_vm.title))])]}),_c('VSpacer'),_vm._t("actions",function(){return [(_vm.dismissable)?_c('VBtn',{attrs:{"icon":""},nativeOn:{"click":function($event){return _vm.$emit('toggle:form:dialog')}}},[_c('VIcon',{attrs:{"color":_vm.closeIconColor}},[_vm._v(" close ")])],1):_vm._e()]})],2)],1):_vm._e(),(_vm.isDialogOpen)?_c('VCardText',{class:{
        'pt-0': true,
        'mt-4': _vm.hideTitle,
        'px-0': _vm.$vuetify.breakpoint.smAndDown
      },style:({flex: '1 1 100%', overflowY: !_vm.scrollable ? 'hidden': 'auto'})},[_c('VForm',{staticStyle:{"height":"100%"},on:{"submit":function($event){$event.preventDefault();}}},[_vm._t("default",null,{"isFormLoading":_vm.isFormLoading,"isFormReady":_vm.isFormReady,"saveForm":_vm.saveForm})],2)],1):_vm._e(),(!_vm.hideActions)?_c('VCardActions',{class:_vm.cardActionsClass,staticStyle:{"flex":"0 1 auto"}},[_vm._t("card-actions",function(){return [(_vm.newActions)?[(!_vm.readonly && _vm.currentModel && _vm.currentModel.id && !_vm.hideDeleteButton)?_c('VBtn',{staticClass:"ml-1",attrs:{"color":"error","disabled":_vm.isFormLoading || _vm.currentModel.isLoading,"loading":_vm.isFormLoading || _vm.currentModel.isLoading},nativeOn:{"click":function($event){_vm.$emit('deleteItem', [_vm.currentModel, () => _vm.$emit('toggle:form:dialog')])}}},[_c('span',{staticClass:"white--text"},[_vm._v("Delete")])]):_vm._e(),_c('VSpacer'),_c('VBtn',{staticStyle:{"box-shadow":"none"},attrs:{"color":"#ececf1","disabled":_vm.isFormLoading || (_vm.currentModel || {}).isLoading},on:{"click":function($event){return _vm.$emit('toggle:form:dialog')}}},[_c('span',{staticStyle:{"color":"#808080"}},[_vm._v("Cancel")])]),_c('VBtn',{staticClass:"ml-4 mr-2",staticStyle:{"box-shadow":"0 1.5px 3px 0 rgba(250, 36, 111, 0.25)"},attrs:{"color":"#60be39","disabled":!_vm.isFormReady || _vm.isFormLoading || (_vm.currentModel || {}).isLoading,"loading":_vm.isFormLoading || (_vm.currentModel || {}).isLoading},on:{"click":_vm.saveForm}},[_c('span',{staticClass:"white--text"},[_vm._v(_vm._s(_vm.buttonText))])])]:[_c('VBtn',{attrs:{"large":"","disabled":_vm.isFormLoading},nativeOn:{"click":function($event){return _vm.$emit('toggle:form:dialog')}}},[_c('span',{style:({
                'color': _vm.actionsTextColor,
              })},[_vm._v(" "+_vm._s(_vm.cancelText)+" ")])]),_c('VSpacer'),_vm._t("footer-actions"),(!_vm.readonly)?_c('VBtn',{attrs:{"large":"","color":"primary wethrive","disabled":_vm.isDisabled,"loading":_vm.isFormLoading},on:{"click":_vm.saveForm}},[_c('span',{style:({
                'color': _vm.actionsTextColor,
              })},[_vm._v(" "+_vm._s(_vm.buttonText)+" ")])]):_vm._e()]]},{"props":{
          isFormLoading: _vm.isFormLoading,
          isFormReady: _vm.isFormReady,
        },"saveForm":_vm.saveForm})],2):_vm._e()],1),_vm._t("extra-form")],2):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }