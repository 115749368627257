var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.welcomeCard)?_c('VCard',{staticClass:"pt-4",style:({
    'background-color': _vm.backgroundColor,
    'box-shadow': _vm.cardShadow,
    ..._vm.cardStyle,
  }),attrs:{"height":_vm.height}},[_c('VCardTitle',{staticClass:"font-weight-bold justify-center",staticStyle:{"font-size":"19px"}},[_vm._v(" "+_vm._s(`What’s a ${_vm.featureName(_vm.modelType, 'singularize').capitalize()}?`)+" ")]),_c('VCardText',{staticClass:"font-weight-medium px-4"},[_vm._v(" "+_vm._s(`Great question, so glad you asked. ${_vm.featureName(_vm.modelType).capitalize()} are the central operating principle of WeThrive. For your programs, grants, etc etc, click below to start your first one.`)+" ")]),_c('VCardActions',{staticClass:"justify-center"},[_c('VBtn',{staticClass:"px-5",attrs:{"color":"primary"},on:{"click":function($event){return _vm.$emit('onClick')}}},[_vm._v(" "+_vm._s(`Create a New ${_vm.featureName(_vm.modelType, 'singularize').capitalize()}`)+" ")])],1)],1):_c('VCard',{class:{
    'program-card': true,
    'hidden-card': _vm.isCardHidden,
    'hide-blur': !_vm.showCount,
  },style:({
    'background-color': _vm.processing ? '#FFFFFFB2' : _vm.backgroundColor,
    'box-shadow': _vm.cardShadow,
    cursor: _vm.processing || _vm.altProcessing ? 'default' : 'pointer',
    ..._vm.cardStyle,
  }),attrs:{"loading":_vm.loading,"height":_vm.height,"href":_vm.locked || _vm.processing || _vm.altProcessing ? null : _vm.href},on:{"click":function($event){$event.preventDefault();return _vm.onCardClick.apply(null, arguments)}},scopedSlots:_vm._u([{key:"progress",fn:function(){return [_c('VProgressLinear',{attrs:{"indeterminate":"","height":"4","color":"deep-purple"}})]},proxy:true}])},[_c('VOverlay',{attrs:{"absolute":"","light":"","value":_vm.isCardHidden || _vm.locked || _vm.showCount}},[(_vm.showCount)?_c('span',{staticStyle:{"color":"#ffffff","z-index":"6","font-size":"24px","font-weight":"800"}},[_vm._v(" +"+_vm._s(_vm.count)+" ")]):_vm._e()]),_c('VOverlay',{attrs:{"absolute":"","light":"","value":_vm.altProcessing}},[_c('div',{staticClass:"text-center px-2 text-body-1 font-weight-bold"},[_vm._v(" Your content is being duplicated. Please check back soon. ")]),_c('div',{staticClass:"ma-4",style:({
        background: `url(${_vm.ProgressImage})`,
        backgroundSize: '100% 100%',
        backgroundRepeat: 'no-repeat',
        height: '36px',
      })},[_c('VRow',{staticClass:"fill-height"},[_c('VCol',{staticClass:"d-flex align-center px-8 py-0"},[_c('span',{staticStyle:{"font-size":"14px","color":"white","font-weight":"700"}},[_vm._v(" Working... ")]),_c('VSpacer'),_c('VTooltip',{attrs:{"top":"","content-class":"progress-tooltip"},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('VIcon',_vm._g({attrs:{"color":"#FFFFFF"}},on),[_vm._v(" mdi-information-outline ")])]}}])},[_c('div',{staticStyle:{"font-size":"14px"}},[_c('span',{staticStyle:{"font-weight":"700"}},[_vm._v("Just a sec..")]),_c('br'),_c('span',{staticStyle:{"font-weight":"400"}},[_vm._v(" Duplication can take a few minutes. "),_c('br'),_vm._v(" Please check back soon. ")])])])],1)],1)],1)]),(_vm.imageCard)?_c('VImg',{staticClass:"white--text align-end",style:({
      'border-radius': '10px',
      opacity: _vm.isCardHidden ? 0.1 : 1,
    }),attrs:{"gradient":"to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)","src":_vm.imageCard.url,"height":_vm.height}},[_c('VCardActions',{staticClass:"text-truncate justify-center"},[_c('span',{style:({
          'font-size': '18px',
          'font-weight': 900,
        })},[_vm._v(" "+_vm._s(_vm.item.title)+" ")])])],1):_vm._e(),_c('VCardText',{staticClass:"d-flex align-center justify-center",staticStyle:{"position":"absolute","top":"36%"}},[(_vm.isCardHidden)?_c('VBtn',{staticStyle:{"color":"#ffffff !important","z-index":"6"},attrs:{"icon":"","outlined":"","rounded":"","disabled":"","height":"40px","width":"40px"}},[_c('VIcon',{staticStyle:{"z-index":"6"},attrs:{"color":"#ffffff !important","size":"20"}},[_vm._v(" mdi-eye-off ")])],1):_vm._e()],1),_c('VCardTitle',{staticClass:"layout row pt-4 pb-2 mx-1 justify-space-between",staticStyle:{"flex":"0 1 64px !important"},attrs:{"primary-title":""}},[_vm._t("card-title",function(){return [(_vm.imageCard == null)?_vm._t("title-text",function(){return [(!_vm.altProcessing)?_c('VAvatar',{style:({
            border: _vm.isCardHidden ? '1px solid #ffffff' : 'none',
          }),attrs:{"size":"40","color":_vm.getAvatarColor}},[_vm._t("avatar",function(){return [(_vm.isCardHidden || _vm.locked)?_c('VIcon',{staticStyle:{"color":"#ffffff !important","z-index":"6"},attrs:{"size":_vm.isCardHidden ? 20 : 24}},[_vm._v(" "+_vm._s(_vm.isCardHidden ? "mdi-eye-off" : "lock")+" ")]):_c('span',{staticClass:"white--text",staticStyle:{"font-size":"18px"}},[_vm._v(" "+_vm._s(_vm.item.emoji || _vm.getAvatarText)+" ")])]})],2):_vm._e()]}):_vm._e(),_c('VSpacer'),_vm._t("title-action",function(){return [(!_vm.hideMenu && !_vm.showCount && !_vm.processing && !_vm.altProcessing)?_c('CardMenu',{attrs:{"item":_vm.item,"item-type":_vm.itemType,"parent-model":_vm.parentModel,"enable-duplicate":_vm.enableDuplicate,"enable-status-action":_vm.$isUserAdmin,"disable-visibility":_vm.disableVisibility,"only-visibility-menu":_vm.onlyVisibilityMenu,"icon-color":_vm.isCardHidden ? 'white' : _vm.visibilityIconColor},on:{"visibilityStatus":_vm.onVisibilityStatus,"enableItemLoading":(status) => _vm.$emit('enableItemLoading', status),"duplicationDone":(result) => _vm.$emit('duplicationDone', result)}},[_c('template',{slot:"additionalMenu"},[_vm._t("menu-items")],2)],2):_vm._e(),(_vm.locked)?_c('VBtn',{staticStyle:{"z-index":"6"},attrs:{"rounded":"","color":"rgb(105 106 108)","height":"40px","width":"40px","min-width":"40px"},nativeOn:{"click":function($event){return _vm.$emit('onClick', _vm.item)}}},[_c('VIcon',{attrs:{"size":"24","color":"#ffffff"}},[_vm._v(" mdi-information-variant ")])],1):_vm._e()]})]})],2),(_vm.imageCard == null)?_c('VCardText',{class:{
      'pb-0': _vm.item.city_id && _vm.item.city,
    },style:({
      'min-height': _vm.contentMinHeight,
    })},[_vm._t("card-content",function(){return [_c('VList',{attrs:{"color":"transparent"}},[_c('VListItem',{staticClass:"mt-n3 mx-n2"},[_c('VListItemContent',[_c('VListItemTitle',{staticStyle:{"line-height":"1.5"}},[_c('VRow',[_c('VCol',{staticClass:"pa-0 text-truncate",attrs:{"cols":"10"}},[_vm._t("before-title"),_c('span',{style:({
                      'font-size': '18px',
                      'font-weight': 900,
                      color: _vm.isCardHidden
                        ? 'rgba(255, 255, 255, 0.5)'
                        : 'rgba(0, 0, 0, 0.87)',
                    })},[_vm._v(" "+_vm._s(_vm.item.title)+" ")]),_c('VSpacer')],2),(!_vm.processing && !_vm.altProcessing)?_c('VCol',{staticClass:"pa-0 d-flex justify-end",attrs:{"cols":"2"}},[_vm._t("link")],2):_vm._e()],1)],1),(_vm.item.city_id && _vm.item.city)?_c('VListItemSubtitle',{staticStyle:{"color":"#000000","font-size":"14px"}},[_c('VIcon',{attrs:{"size":"13px"}},[_vm._v(" mdi-map-marker ")]),_vm._v(" "+_vm._s(_vm.item.city.name.trim())+", "+_vm._s(_vm.item.city.parent.name)+" ")],1):_vm._e()],1)],1),(_vm.item.content)?_c('VListItem',{staticClass:"mx-n2"},[_c('VListItemContent',{staticClass:"pa-0"},[_c('p',{directives:[{name:"linkified",rawName:"v-linkified"}],staticClass:"formatted content",domProps:{"innerHTML":_vm._s(_vm.item.content)}})])],1):_vm._e()],1)]},{"isCardHidden":_vm.isCardHidden})],2):_vm._e(),(_vm.processing)?_c('VCardActions',{staticClass:"px-4"},[_c('div',{staticClass:"fluid",style:({
        background: `url(${_vm.ProgressImage})`,
        backgroundSize: '100% 100%',
        backgroundRepeat: 'no-repeat',
        height: '36px',
      })},[_vm._t("progress-content")],2)]):(!_vm.hideActions)?_c('VCardActions',{staticClass:"px-4"},[_vm._t("actions",function(){return [_c('AvatarGroup',{staticClass:"pb-2",attrs:{"tooltip-text":"Members","preview-users":_vm.item.users,"total-users":_vm.item.students_count + _vm.item.mentors_count,"custom-style":{
          opacity: _vm.isCardHidden ? 0.4 : 1,
        },"href":_vm.locked ? undefined : `${_vm.href}#people`},on:{"showDetails":function($event){_vm.locked ? _vm.$emit('onClick', _vm.item) : _vm.$router.push(`${_vm.href}#people`)}}}),_c('VSpacer'),_c('VChip',{staticClass:"mb-2 mentor-chip",style:({
          cursor: _vm.href ? 'pointer' : 'default',
        }),attrs:{"outlined":"","href":_vm.locked ? undefined : `${_vm.href}#people`},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();_vm.locked ? _vm.$emit('onClick', _vm.item) : _vm.$router.push(`${_vm.href}#people`)}}},[_vm._v(" "+_vm._s(_vm.item.mentors_count || 0)+" "+_vm._s(_vm.featureName("Mentors", "pluralize"))+" ")])]})],2):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }