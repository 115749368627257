<template>
  <Autocomplete
    v-model="form[exactModelType]"
    hide-details
    :return-object="returnObject"
    :class="customClass"
    :getter="getItems"
    :label="label"
    :multiple="multiple"
    :menu-props="{
      maxHeight: '272px',
    }"
    :loading="activity.isLoading"
    :placeholder="placeholder"
    :prepend-inner-icon="prependInnerIcon"
    :items="options[exactModelType]"
    :searchable="searchText && searchText.length > 0"
    @change="$emit('change')"
    @input="$emit('input', form[exactModelType])"
    @searchTextInput="onSearchTextInput"
    @searchComplete="enableInfiniteLoading = true"
  >
    <template
      v-if="options[exactModelType].length"
      #append-item
    >
      <VProgressLinear
        v-if="activity.isLoading"
        indeterminate
        rounded
        height="6"
        color="primary"
      />
      <div v-intersect="endIntersect" />
    </template>
  </Autocomplete>
</template>

<script>
import { mapActions } from "vuex";
import FormMixin from "@/mixins/Form";
export default {
  mixins: [FormMixin],
  props: {
    section: {
      type: String,
      default: null,
    },
    item: {
      type: Object,
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
    placeholder: {
      type: String,
      default: null,
    },
    itemText: {
      type: String,
      default: "title",
    },
    itemValue: {
      type: String,
      default: "id",
    },
    searchInput: {
      type: String,
      default: null,
    },
    hint: {
      type: String,
      default: null,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    chips: {
      type: Boolean,
      default: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    filled: {
      type: Boolean,
      default: false,
    },
    dense: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    outlined: {
      type: Boolean,
      default: true,
    },
    value: {
      type: [Array, Number, String],
      default: null,
    },
    customClass: {
      type: [Object, String],
      default: null,
    },
    prependInnerIcon: {
      type: String,
      default: null,
    },
    returnObject: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      page: 1,
      searchText: null,
      isLastPage: false,
      skipFormValidation: true,
      enableInfiniteLoading: true,
      canGetModel: false,
      options: {
        programs: [],
        lessons: [],
        deliverables: [],
        primary_contents: [],
        pipelines: [],
        faqs: [],
      },
    };
  },
  computed: {
    exactModelType() {
      return this.modelType == 'contents' ? 'primary_contents' : this.modelType;
    },
  },
  created() {
    this.modelType = this.section;
  },
  mounted() {
    this.emitLoading();
    this[`doGet${this.modelType.capitalize()}`]({ page: this.page }).then((result) => {
      this.options[this.exactModelType] = result.data;
      this.isLastPage = result.to === result.total;
      this.emitLoadingDone();
    });
  },
  methods: {
    ...mapActions({
      doGetContents: "content/getAll",
      doGetFaqs: "faq/getAll",
      doGetLessons: "lesson/getAll",
      doGetPrograms: "program/getAll",
      doGetPipelines: "pipeline/getAll",
      doGetDeliverables: "deliverable/getAll",
    }),
    endIntersect(entries, observer, isIntersecting) {
      if(isIntersecting && this.enableInfiniteLoading && !this.isLastPage && !this.activity.isLoading) {
        const params = {
          page: ++this.page,
        };
        if(this.searchText) {
          params.filter = { 
            s: this.searchText,
          };
          params.s = this.searchText;
        }
        this.emitLoading();
        this[`doGet${this.modelType.capitalize()}`](params).then((result) => {
          this.options[this.exactModelType] = this.options[this.exactModelType].concat(result.data);
          this.isLastPage = result.to === result.total;
          this.emitLoadingDone();
        });
      }
    },
    onSearchTextInput(searchText) {
      this.searchText = searchText;
      this.enableInfiniteLoading = !(searchText && searchText.length > 0);
    },
    getItems(params) {
      return this[`doGet${this.modelType.capitalize()}`](params).then((result) => {
        this.options[this.exactModelType] = result.data;
        this.enableInfiniteLoading = true;
        this.page = 1;
        return result;
      });
    },
  },
};
</script>
