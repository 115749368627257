import Vue from "vue";
import Sugar from "sugar";
import Inflections from "sugar-inflections";
import * as Exceptions from "@/config/utilities/Exceptions";
import dotenv from "dotenv";
import linkify from "vue-linkify";
import faker from "faker";
import { mergeStyles, mergeClasses } from "vuetify/lib/util/mergeData";
import * as VueDeepSet from "vue-deepset";
import { inArray } from "highcharts";

dotenv.config();

Sugar.extend({
	namespaces: [Array, String, Number, Object],
});

Array.addItem = function(target, item, key, push) {
	const addData = function(_target, _item, _key) {
		if (_target instanceof Array) {
			const index = _target.findIndex((current) => {
				if (
					current instanceof Object &&
					_item instanceof Object &&
					current[_key] === _item[_key]
				) {
					return true;
				} else if (
					current instanceof Object &&
					!(_item instanceof Object) &&
					current[_key] === _item
				) {
					return true;
				} else if (_item instanceof Object && current === _item[_key]) {
					return true;
				} else {
					return _item === current;
				}
			});
			if (index > -1) {
				Vue.set(_target, index, 1, _item);
			} else if (push) {
				Vue.$log.debug(_target, _item);
				_target.push(_item);
			} else {
				_target.unshift(_item);
			}
		}
	};
	const realKey = key || "id";
	addData(target, item, realKey);
};

Array.updateItem = function(target, item, key, addMissing) {
	const updateData = function(_target, _item, _key, _addMissing) {
		let data, index;
		if (_target instanceof Array) {
			index = _target.findIndex((current) => {
				if (
					current instanceof Object &&
					_item instanceof Object &&
					current[_key] === _item[_key]
				) {
					return true;
				} else if (
					current instanceof Object &&
					!(_item instanceof Object) &&
					current.id === _item
				) {
					return true;
				} else if (_item instanceof Object && current === _item[_key]) {
					return true;
				} else {
					return _item === current;
				}
			});
			data = index > -1 ? _target[index] : null;
		} else {
			data = _item;
			index = 0;
		}
		if (data) {
			data = {
				...data,
				..._item,
			};
			Vue.set(_target, index, data);
		} else if (_addMissing) {
			Array.addItem(_target, _item, _key);
		}
	};
	const realKey = key || "id";
	updateData(target, item, realKey, addMissing);
};

String.prototype.dedupe = function(splitter) {
	let str = this;
	const separator = splitter || " ";
	return this.split(separator)
		.filter(function(item, i, allItems) {
			return i == allItems.indexOf(item);
		})
		.join(separator);
};

String.prototype.slugify = function() {
	let str = this;
	if (!(typeof str === "string")) {
		return "";
	}
	str = str.replace(/^\s+|\s+$/g, ""); // trim
	str = str.toLowerCase();

	// remove accents, swap ñ for n, etc
	var from = "åàáãäâèéëêìíïîòóöôùúüûñç·/_,:;";
	var to = "aaaaaaeeeeiiiioooouuuunc------";

	for (var i = 0, l = from.length; i < l; i++) {
		str = str.replace(new RegExp(from.charAt(i), "g"), to.charAt(i));
	}

	str = str
		.replace(/[^a-z0-9 -]/g, "") // remove invalid chars
		.replace(/\s+/g, "-") // collapse whitespace and replace by -
		.replace(/-+/g, "-") // collapse dashes
		.replace(/^-+/, "") // trim - from start of text
		.replace(/-+$/, ""); // trim - from end of text

	return str;
};

window._loadedUrls = window._loadedUrls || [];
Vue.mixin({
	data() {
		return {
			testHash: "#shiken-mode",
			debouncedEvents: {},
			isIntercomShown: false,
			isMenuExpanded: JSON.parse(localStorage.getItem("forceMenuToExpand")) === null ? true : JSON.parse(localStorage.getItem("forceMenuToExpand")),
		};
	},
	computed: {
		$showLeftMainMenu() {
			return this.$route.name !== "program.index" 
				&& this.$route.name !== "account"
				&& this.$route.name !== "chat.index"
				&& this.$route.fullPath !== "/settings#automations";
		},
		$isMenuExpanded() {
			return this.isMenuExpanded;
		},
	},
	mounted() {
		this.$bus.$on("forceMenuToExpand", (status) => this.isMenuExpanded = status);
	},
	methods: {
		setGroupItemLocalStorage(keyName, groupKeyName, valueKeyName, value) {
			const item = JSON.parse(localStorage.getItem(keyName));
			if(item) {
				if(item.hasOwnProperty(groupKeyName)) {
					item[groupKeyName][valueKeyName] = value;
					localStorage.setItem(keyName, JSON.stringify(item));	
				} else {
					localStorage.setItem(keyName, JSON.stringify({
						...item,
						[groupKeyName]: {
							[valueKeyName]: value,
						},
					}));
				}
			} else {
				localStorage.setItem(keyName, JSON.stringify({
					[groupKeyName]: {
						[valueKeyName]: value,
					}
				}));
			}
		},
		getGroupItemLocalStorage(keyName, groupKeyName, valueKeyName) {
			const item = JSON.parse(localStorage.getItem(keyName));
			if(groupKeyName && item) {
				return valueKeyName ? item[groupKeyName][valueKeyName] : item[groupKeyName];
			}
			return item;
		},
		removeGroupItemLocalStorage(keyName, groupKeyName, valueKeyName) {
			const item = JSON.parse(localStorage.getItem(keyName));
			this.$delete(item[groupKeyName], valueKeyName);
			localStorage.setItem(keyName, JSON.stringify(item));
		},
		debouncer(fn, key, timeout) {
			clearTimeout(this.debouncedEvents[key]);
			delete this.debouncedEvents[key];
			const realTimeout = parseInt(timeout) || 750;
			this.debouncedEvents[key] = setTimeout(fn, realTimeout);
		},
		mockData(params) {
			const parameters = params || {};
			const result = {};
			for (const param in parameters) {
				const value = parameters[param];
				const key = `{{${value}}}`;
				try {
					result[param] = faker.fake(key) || value;
				} catch (e) {
					result[param] = value;
				}
			}
			return Vue.observable(result);
		},
		cloneObject(object) {
			return Vue.observable(Object.assign({}, object));
		},
		/**
		 * Is the given value true?
		 * @param {string|number|boolean} value
		 */
		isTrue(value) {
			const result =
				value === true ||
				value == "1" ||
				value == 1 ||
				value == "t" ||
				value == "true" ||
				value == "yes" ||
				value == "on";
			return result;
		},
		/**
		 * Beta features are denoted by environment variables with a value of 2
		 * @param {int} value
		 */
		isBetaFeature(value) {
			let resolvedValue = value;
			if (typeof value === "string" && value.length > 1) {
				const singularKey = `VUE_APP_FEATURE_${value.toUpperCase()}`;
				const pluralKey = `VUE_APP_FEATURE_${value.pluralize().toUpperCase()}`;
				resolvedValue = process.env[singularKey] || process.env[pluralKey];
			}
			const result = resolvedValue == "2" || parseInt(resolvedValue) == 2;
			// this.$log.debug("Is beta feature?", value, resolvedValue, result);
			return result;
		},
		/**
		 * Is an enabled feature
		 * @param {int} value
		 */
		isEnabledFeature(feature) {
			if (this.isBetaFeature(feature)) {
				return true;
			}
			if (typeof feature === "string" && feature.length > 1) {
				const index = feature.toUpperCase().startsWith("VUE_APP_FEATURE_")
					? feature.toUpperCase()
					: "VUE_APP_FEATURE_" + feature.toUpperCase();
				if (this.env.hasOwnProperty(index)) {
					this.$log.debug(
						"Is enabled feature?",
						feature,
						index,
						this.env[index],
						this.isTrue(this.env[index])
					);
					return this.isTrue(this.env[index]);
				}
			}
			this.$log.debug(
				"Is enabled feature invalid feature",
				feature,
				false,
				typeof feature
			);
			return false;
		},
		async waitFor(validator, _timeoutMs) {
			let timeoutMs = _timeoutMs || 5000;
			return new Promise((r, j) => {
				let check = () => {
					if (validator() === true) {
						r();
					} else if ((timeoutMs -= 100) < 0) {
						j(
							new Exceptions.IgnorableException(
								`Timed out after ${timeoutMs}ms waiting for condition`
							)
						);
					} else {
						setTimeout(check, 100);
					}
				};
				setTimeout(check, 100);
			});
		},
		loadUrl() {
			// Function which returns a function:
			// https://davidwalsh.name/javascript-functions
			function _load(tag) {
				return function(url, callback) {
					// This promise will be used by Promise.all to determine success or failure
					return new Promise(function(resolve, reject) {
						if (window._loadedUrls.indexOf(url) !== -1) {
							resolve(url);
						} else {
							var element = document.createElement(tag);
							var parent = "body";
							var attr = "src";

							// Important success and error for the promise
							element.onload = function() {
								callback instanceof Function
									? resolve(callback())
									: resolve(url);
							};
							element.onerror = function() {
								reject(url);
							};

							// Need to set different attributes depending on tag type
							switch (tag) {
								case "script":
									element.async = true;
									break;
								case "link":
									element.type = "text/css";
									element.rel = "stylesheet";
									attr = "href";
									parent = "head";
							}

							// Inject into document to kick off loading
							element[attr] = url;
							document[parent].appendChild(element);
							window._loadedUrls.push(url);
						}
					});
				};
			}

			return {
				css: _load("link"),
				js: _load("script"),
				img: _load("img"),
			};
		},
		/**
		 * Get the feature name based on the user's renamed items
		 *
		 * @param string key
		 * @param string|bool inflect. Set to false to return the raw value
		 * @param array args
		 * @param object userTeam
		 *
		 * 2return string
		 */
		featureName(key, inflect, args, userTeam) {
			const team = userTeam || this.$team;
			if (!key) {
				return "(not set)";
			}
			let result = key;
			let sanitizedKey = key.toLowerCase();
			let roleIndex = [
				"mentor",
				"mentors",
				"student",
				"students",
				"organization-admin",
				"organization-admins",
			].indexOf(sanitizedKey);
			if (roleIndex > -1) {
				sanitizedKey = sanitizedKey.singularize();
				sanitizedKey = sanitizedKey === "mentor" ? "user" : sanitizedKey;
				result = team.hasOwnProperty(sanitizedKey + "_role_name")
					? team[sanitizedKey + "_role_name"]
					: sanitizedKey;
				// Return the role based on the origina incoming value
				result =
					(roleIndex + 1) % 2 === 0 ? result.pluralize() : result.singularize();
				// Disabling inflextion for now;
			} else if (
				team instanceof Object &&
				team.hasOwnProperty("feature_names")
			) {
				let inflected = [key.singularize(), key.pluralize()];
				for (let i in inflected) {
					const inflectedKey = inflected[i].toLowerCase();
					if (team.feature_names.hasOwnProperty(inflectedKey)) {
						result = team.feature_names[inflectedKey];
						/* *
						 * Avoided the term FAQs to be singularized i.e FAQ programmatically.
						 * NOTE: This problem should be fixed by updating the "faq" key to "faqs" in the api.
						 */
						if (inflectedKey == "faq" || inflectedKey == "content") {
							break;
						}
						// Singularize or pluralize based on the original value passed in
						result =
							parseInt(i) === 0 ? result.singularize() : result.pluralize();
						break;
					}
				}
			} else {
				result = key || "(not set)";
			}
			if (!result) {
				return key;
			}

			// Also return the correct case based on the case of the original value
			if (inflect !== false) {
				if (key == key.toUpperCase()) {
					result = result.toUpperCase();
				} else if (key == key.toUpperCase()) {
					result = result.toUpperCase();
				} else {
					result =
						key[0] == key[0].toLowerCase()
							? result.toLowerCase()
							: result.capitalize();
				}
			}
			const inflections = inflect instanceof Array ? inflect : [inflect];
			inflections.map((i) => {
				try {
					result = result[inflect](...(args || []));
				} catch (e) {}
			});

			return result.dedupe();
		},
		checkMentorPermission(modelType, program) {
			// Check user role of the program
			if(program) {
				if(!this.isUserMentorForProgram(program)) {
					return false;
				}
			} else {
				if(!this.hasUserMentorInPrograms()) {
					return false;
				}
			}
			const feature = modelType.toLowerCase();
			if(program) {
				const permissions = this.getProgramPermission(program);
				if(feature == "invite") {
					return permissions.mentor_can_invite_user;
				}
				return permissions[`mentor_can_create_${feature}`];
			}
			const filteredPrograms = this.filterProgramsHavePermission(feature);
			if(feature == "invite") {
				return this.$teamSettings.mentor.can_invite_user 
					&& (this.$teamSettings.mentor.can_invite_user.value || filteredPrograms.length > 0);
			}
			return (
				this.$teamSettings.mentor[`can_create_${feature}`] 
					&& (this.$teamSettings.mentor[`can_create_${feature}`].value || filteredPrograms.length > 0)
			);
		},
		getProgramPermission(program) {
			const user = program.mentors.concat(program.students).find(user => user.id === this.$user.id);
			const permissions = user.attachment_meta.permissions;
			return {
				...this.teamSettingsForMentor(),
				...permissions,
			};
		},
		getPermissionForProgram(programId, attachedPrograms) {
			let programs = [];
			if(attachedPrograms && attachedPrograms instanceof Array) {
				programs = attachedPrograms;
			} else {
				programs = this.$user.program_access;
			}
			const currentProgram = programs.find((program) => program.id === programId);
			const permissions = JSON.parse(currentProgram.pivot.permissions);
			return {
				...this.teamSettingsForMentor(),
				...permissions,
			};
		},
		teamSettingsForMentor() {
			return {
				"mentor_can_create_content": this.$teamSettings.mentor.can_create_content.value,
				"mentor_can_create_deliverable": this.$teamSettings.mentor.can_create_deliverable.value,
				"mentor_can_create_faq": this.$teamSettings.mentor.can_create_faq.value,
				"mentor_can_create_lesson": this.$teamSettings.mentor.can_create_lesson.value,
				"mentor_can_invite_user": this.$teamSettings.mentor.can_invite_user.value,
			};
		},
		isUserMentorForProgram(program) {
			if(!this.$isUserAdmin) {
				const user = program.mentors.concat(program.students).find(user => user.id === this.$user.id);
				return user.attachment_meta.type == "mentor";
			}
			return false;
		},
		isUserStudentForProgram(program) {
			if(!this.$isUserAdmin) {
				const user = program.mentors.concat(program.students).find(user => user.id === this.$user.id);
				return user.attachment_meta.type == "student";
			}
			return false;
		},
		hasUserMentorInPrograms() {
			const userIndex = this.$user.program_access.findIndex((program) => program.pivot.type == "mentor" && !this.isProgramMonetized(program));
			return userIndex != -1;
		},
		filterProgramsHavePermission(feature, attachedPrograms) {
			let programs = [];
			if(attachedPrograms && attachedPrograms instanceof Array) {
				programs = attachedPrograms;
			} else {
				programs = this.$user.program_access;
			}
			return programs.filter((program) => {
				if(!this.isProgramMonetized(program)) {
					let permissions = JSON.parse(program.pivot.permissions);
					if(this.$teamSettings.mentor[`can_create_${feature}`].value) {
						if(Object.keys(permissions).length === 0 || (Object.keys(permissions).length && permissions[`mentor_can_create_${feature}`])) {
							return program;
						}
					} else if(Object.keys(permissions).length && permissions[`mentor_can_create_${feature}`]) {
						return program;
					}
				}
			})
		},
		isProgramMonetized(program) {
			return (
				this.isEnabledFeature("monetization") &&
				program.product != null &&
				program.product.transactions === null
			);
		},
		validRoles(roles, except = []) {
			if(roles && roles.length) {
				const invalidRoles = ["developer", ...except];
				return roles
					.filter((role) => invalidRoles.indexOf(role.id) === -1 && invalidRoles.indexOf(role.value) === -1)
					.map((role) => {
						role.name = role.text = role.title = this.featureName(role.value, "capitalize");
						return role;
					});
			}
			return [];
		},
		grammaticalFeatureName(key, value, team) {
			return this.featureName(
				key,
				value == 1 ? "singularize" : "pluralize",
				null,
				team
			);
		},
		getAllClassesOrStyles(userOptions, item, _defaultClasses, isStyle) {
			const defaultClasses = _defaultClasses || "";
			if (userOptions instanceof Function) {
				userOptions = userOptions(item);
			}

			return this.combineClassesOrStyles(
				userOptions,
				_defaultClasses || "",
				isStyle
			);
		},
		combineClassesOrStyles(into, from, isStyle) {
			return isStyle ? mergeStyles(into, from) : mergeClasses(into, from);
		},
		fallbackCopyTextToClipboard(text) {
			var textArea = document.createElement("textarea");
			textArea.value = text;
			document.body.appendChild(textArea);
			textArea.focus();
			textArea.select();

			try {
				var successful = document.execCommand("copy");
				var msg = successful ? "successful" : "unsuccessful";
				this.$log.debug("Using fallback copy mode");
				this.$bus.$emit("notificationMessage", "Copied to your clipboard!");
			} catch (err) {
				this.$log.warn("Error coping", err);
			}

			document.body.removeChild(textArea);
		},
		copyTextToClipboard(text) {
			if (!navigator.clipboard) {
				this.fallbackCopyTextToClipboard(text);
				return;
			}
			navigator.clipboard.writeText(text).then(() => {
				this.$bus.$emit("notificationMessage", "Copied to your clipboard!");
			});
		},
		getOS() {
			var userAgent = window.navigator.userAgent,
				platform = window.navigator.platform,
				macosPlatforms = ["Macintosh", "MacIntel", "MacPPC", "Mac68K"],
				windowsPlatforms = ["Win32", "Win64", "Windows", "WinCE"],
				iosPlatforms = ["iPhone", "iPad", "iPod"],
				os = null;

			if (/Mac/.test(platform)) {
				os = "MacOS";
			} else if (iosPlatforms.indexOf(platform) !== -1) {
				os = "iOS";
			} else if (/Win/.test(platform)) {
				os = "Windows";
			} else if (/Android/.test(userAgent)) {
				os = "Android";
			} else if (/Linux/.test(platform)) {
				os = "Linux";
			}

			return os;
		},
		getHasActivity(_item) {
			const item = _item || {};
			return item.isDeleting || item.isSaving || item.isLoading;
		},
		getIsDisabled(_item) {
			const item = _item || {};
			return item.isDisabled;
		},
		getFullyQualifiedUrlForItem(name, item) {
			return window.location.origin + this.getHrefForItem(name, item);
		},
		getRouteForItem(name, item) {
			let params = {
				name,
			};
			if (item instanceof Object) {
				const itemParams =
					item.hasOwnProperty("params") && item.params instanceof Object
						? item
						: { params: item };
				params = { ...params, ...itemParams };
			} else {
				params.params = {
					id: item,
				};
			}
			// Adding team/workspace slug if not exist in the params
			if(!params.params.hasOwnProperty("team")) {
				params.params.team = this.$team.slug;
			}
			return params;
		},
		getHrefForItem(name, item) {
			const params = this.getRouteForItem(name, item);
			// console.log("Resolving href for item", name, item, params);
			return this.getHref(params);
		},
		getHref(route) {
			const url = (this.$router.resolve(route) || {}).href || "#";
			// console.log("Resolving href for ", route, url);
			return url;
		},
		findDuplicatesInTwoArrays(array1, array2, key) {
			return array1.filter(item1 => 
				array2.some(item2 => item1[key] === item2[key])
			);
		},
		findDuplicates(arr, key, _returnDuplicates = false) {
			const lookup = arr.reduce((acc, item) => {
			  	acc[item[key]] = (acc[item[key]] || 0) + 1;
			  	return acc;
			}, {});
			if(_returnDuplicates) {
				return arr.filter(item => lookup[item[key]] > 1);
			}
			return Object.keys(lookup).filter(a => lookup[a] > 1);
		},
	},
});

Vue.directive("linkified", linkify);
Vue.use(VueDeepSet);

export default {};
