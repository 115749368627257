<template>
  <VCard
    v-if="welcomeCard"
    class="pt-4"
    :height="height"
    :style="{
      'background-color': backgroundColor,
      'box-shadow': cardShadow,
      ...cardStyle,
    }"
  >
    <VCardTitle 
      class="font-weight-bold justify-center"
      style="font-size: 19px;"
    >
      {{ `What’s a ${featureName(modelType, 'singularize').capitalize()}?` }} 
    </VCardTitle>
    <VCardText class="font-weight-medium px-4">
      {{ `Great question, so glad you asked. ${featureName(modelType).capitalize()} are the central operating principle of WeThrive. For your programs, grants, etc etc, click below to start your first one.` }}
    </VCardText>
    <VCardActions class="justify-center">
      <VBtn
        color="primary"
        class="px-5"
        @click="$emit('onClick')"
      >
        {{ `Create a New ${featureName(modelType, 'singularize').capitalize()}` }}
      </VBtn>
    </VCardActions>
  </VCard>
  <VCard
    v-else
    :loading="loading"
    :height="height"
    :class="{
      'program-card': true,
      'hidden-card': isCardHidden,
      'hide-blur': !showCount,
    }"
    :style="{
      'background-color': processing ? '#FFFFFFB2' : backgroundColor,
      'box-shadow': cardShadow,
      cursor: processing || altProcessing ? 'default' : 'pointer',
      ...cardStyle,
    }"
    :href="locked || processing || altProcessing ? null : href"
    @click.prevent="onCardClick"
  >
    <template #progress>
      <VProgressLinear
        indeterminate
        height="4"
        color="deep-purple"
      />
    </template>
    <VOverlay
      absolute
      light
      :value="isCardHidden || locked || showCount"
    >
      <span
        v-if="showCount"
        style="color: #ffffff; z-index: 6; font-size: 24px; font-weight: 800;"
      > 
        +{{ count }} 
      </span>
    </VOverlay>
    <VOverlay
      absolute
      light
      :value="altProcessing"
    >
      <div class="text-center px-2 text-body-1 font-weight-bold"> 
        Your content is being duplicated.  Please check back soon.
      </div>
      <div
        class="ma-4"
        :style="{
          background: `url(${ProgressImage})`,
          backgroundSize: '100% 100%',
          backgroundRepeat: 'no-repeat',
          height: '36px',
        }"
      >
        <VRow class="fill-height">
          <VCol class="d-flex align-center px-8 py-0">
            <span style="font-size: 14px; color: white; font-weight: 700;">
              Working...
            </span>
            <VSpacer />
            <VTooltip
              top
              content-class="progress-tooltip"
            >
              <template #activator="{ on }">
                <VIcon 
                  v-on="on"
                  color="#FFFFFF"
                >
                  mdi-information-outline
                </VIcon>
              </template>
              <div style="font-size: 14px;">
                <span style="font-weight: 700;">Just a sec..</span>
                <br>
                <span style="font-weight: 400;">
                  Duplication can take a few minutes.
                  <br>
                  Please check back soon.
                </span>
              </div>
            </VTooltip>
          </VCol>
        </VRow>
      </div>
    </VOverlay>
    <VImg
      v-if="imageCard"
      class="white--text align-end"
      gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
      :style="{
        'border-radius': '10px',
        opacity: isCardHidden ? 0.1 : 1,
      }"
      :src="imageCard.url"
      :height="height"
    >
      <VCardActions class="text-truncate justify-center">
        <span
          :style="{
            'font-size': '18px',
            'font-weight': 900,
          }"
        >
          {{ item.title }}
        </span>
      </VCardActions>
    </VImg>
    <VCardText
      class="d-flex align-center justify-center"
      style="position: absolute; top: 36%;"
    >
      <VBtn
        v-if="isCardHidden"
        icon
        outlined
        rounded
        disabled
        height="40px"
        width="40px"
        style="color: #ffffff !important; z-index: 6;"
      >
        <VIcon
          color="#ffffff !important"
          style="z-index: 6;"
          size="20"
        >
          mdi-eye-off
        </VIcon>
      </VBtn>
    </VCardText>
    <VCardTitle
      primary-title
      style="flex: 0 1 64px !important;"
      class="layout row pt-4 pb-2 mx-1 justify-space-between"
    >
      <slot name="card-title">
        <slot
          v-if="imageCard == null"
          name="title-text"
        >
          <VAvatar
            v-if="!altProcessing"
            size="40"
            :color="getAvatarColor"
            :style="{
              border: isCardHidden ? '1px solid #ffffff' : 'none',
            }"
          >
            <slot name="avatar">
              <VIcon
                v-if="isCardHidden || locked"
                style="color: #ffffff !important; z-index: 6;"
                :size="isCardHidden ? 20 : 24"
              >
                {{ isCardHidden ? "mdi-eye-off" : "lock" }}
              </VIcon>
              <span
                v-else
                class="white--text"
                style="font-size: 18px;"
              >
                {{ item.emoji || getAvatarText }}
              </span>
            </slot>
          </VAvatar>
        </slot>
        <VSpacer />
        <slot name="title-action">
          <CardMenu
            v-if="!hideMenu && !showCount && !processing && !altProcessing"
            :item="item"
            :item-type="itemType"
            :parent-model="parentModel"
            :enable-duplicate="enableDuplicate"
            :enable-status-action="$isUserAdmin"
            :disable-visibility="disableVisibility"
            :only-visibility-menu="onlyVisibilityMenu"
            :icon-color="isCardHidden ? 'white' : visibilityIconColor"
            @visibilityStatus="onVisibilityStatus"
            @enableItemLoading="(status) => $emit('enableItemLoading', status)"
            @duplicationDone="(result) => $emit('duplicationDone', result)"
          >
            <template slot="additionalMenu">
              <slot name="menu-items" />
            </template>
          </CardMenu>
          <VBtn
            v-if="locked"
            rounded
            color="rgb(105 106 108)"
            height="40px"
            width="40px"
            min-width="40px"
            style="z-index: 6;"
            @click.native="$emit('onClick', item)"
          >
            <VIcon
              size="24"
              color="#ffffff"
            >
              mdi-information-variant
            </VIcon>
          </VBtn>
        </slot>
      </slot>
    </VCardTitle>
    <VCardText
      v-if="imageCard == null"
      :class="{
        'pb-0': item.city_id && item.city,
      }"
      :style="{
        'min-height': contentMinHeight,
      }"
    >
      <slot
        name="card-content"
        :isCardHidden="isCardHidden"
      >
        <VList color="transparent">
          <VListItem class="mt-n3 mx-n2">
            <VListItemContent>
              <VListItemTitle style="line-height: 1.5;">
                <VRow>
                  <VCol
                    cols="10"
                    class="pa-0 text-truncate"
                  >
                    <slot name="before-title" />
                    <span
                      :style="{
                        'font-size': '18px',
                        'font-weight': 900,
                        color: isCardHidden
                          ? 'rgba(255, 255, 255, 0.5)'
                          : 'rgba(0, 0, 0, 0.87)',
                      }"
                    >
                      {{ item.title }}
                    </span>
                    <VSpacer />
                  </VCol>
                  <VCol
                    v-if="!processing && !altProcessing"
                    cols="2"
                    class="pa-0 d-flex justify-end"
                  >
                    <slot name="link" />
                  </VCol>
                </VRow>
              </VListItemTitle>
              <VListItemSubtitle
                v-if="item.city_id && item.city"
                style="color: #000000; font-size: 14px;"
              >
                <VIcon size="13px">
                  mdi-map-marker
                </VIcon>
                {{ item.city.name.trim() }}, {{ item.city.parent.name }}
              </VListItemSubtitle>
            </VListItemContent>
          </VListItem>
          <VListItem
            v-if="item.content"
            class="mx-n2"
          >
            <VListItemContent class="pa-0">
              <p
                v-linkified
                v-html="item.content"
                class="formatted content"
              />
            </VListItemContent>
          </VListItem>
        </VList>
      </slot>
    </VCardText>
    <VCardActions
      v-if="processing"
      class="px-4"
    >
      <div
        class="fluid"
        :style="{
          background: `url(${ProgressImage})`,
          backgroundSize: '100% 100%',
          backgroundRepeat: 'no-repeat',
          height: '36px',
        }"
      >
        <slot name="progress-content" />
      </div>
    </VCardActions>
    <VCardActions
      v-else-if="!hideActions"
      class="px-4"
    >
      <slot name="actions">
        <AvatarGroup
          tooltip-text="Members"
          class="pb-2"
          :preview-users="item.users"
          :total-users="item.students_count + item.mentors_count"
          :custom-style="{
            opacity: isCardHidden ? 0.4 : 1,
          }"
          :href="locked ? undefined : `${href}#people`"
          @showDetails="
            locked ? $emit('onClick', item) : $router.push(`${href}#people`)
          "
        />
        <VSpacer />
        <VChip
          outlined
          class="mb-2 mentor-chip"
          :href="locked ? undefined : `${href}#people`"
          :style="{
            cursor: href ? 'pointer' : 'default',
          }"
          @click.prevent.stop="
            locked ? $emit('onClick', item) : $router.push(`${href}#people`)
          "
        >
          {{ item.mentors_count || 0 }} {{ featureName("Mentors", "pluralize") }}
        </VChip>
      </slot>
    </VCardActions>
  </VCard>
</template>
<script>
import ProgressImage from "@/images/progress-bar.gif";
import AvatarGroup from "@/components/Elements/Data/AvatarGroup";
import CardMenu from "@/components/Elements/Navigation/CardMenu";

export default {
  name: "IndexCard",
  props: {
    item: {
      type: Object,
      default: () => {
        return {};
      },
    },
    parentModel: {
      type: Object,
      default: null,
    },
    itemType: {
      type: String,
      default: "Program",
    },
    cardStyle: {
      type: Object,
      default: () => {
        return {};
      },
    },
    hideActions: {
      type: Boolean,
      default: false,
    },
    onlyVisibilityMenu: {
      type: Boolean,
      default: false,
    },
    height: {
      type: String,
      default: "110px",
    },
    contentMinHeight: {
      type: String,
      default: "130px",
    },
    backgroundColor: {
      type: String,
      default: "#ffffff",
    },
    cardShadow: {
      type: String,
      default: "0 3px 8px 0 rgba(216, 220, 240, 0.5)",
    },
    visibilityIconColor: {
      type: String,
      default: "inherit",
    },
    avatarColor: {
      type: String,
      default: "#fdbf14",
    },
    imageCard: {
      type: Object,
      default: null,
    },
    href: {
      type: String,
      default: null,
    },
    hideMenu: {
      type: Boolean,
      default: false,
    },
    locked: {
      type: Boolean,
      default: false,
    },
    count: {
      type: Number,
      default: 0,
    },
    showCount: {
      type: Boolean,
      default: false,
    },
    disableVisibility: {
      type: Boolean,
      default: false,
    },
    checkVisibility: {
      type: Boolean,
      default: true,
    },
    enableDuplicate: {
      type: Boolean,
      default: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    processing: {
      type: Boolean,
      default: false,
    },
    altProcessing: {
      type: Boolean,
      default: false,
    },
    welcomeCard: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    AvatarGroup,
    CardMenu,
  },
  data() {
    return {
      ProgressImage,
      modelType: "program",
      isCardHidden: false,
    };
  },
  watch: {
    "item.visible_to": {
      handler: function(value) {
        if(this.itemType.toLowerCase() == "program" && this.checkVisibility) {
          if(this.disableVisibility || this.showCount) {
            this.isCardHidden = false;
          } else {
            if(value instanceof Array && value.length === 0) {
              this.isCardHidden = true;
            } else {
              let roles = Object.keys(value);
              // Set card hidden when only org admin is available in Visible To
              this.isCardHidden = roles.length === 1 && roles[0] === "organization-admin";
            }
          }
        }
      },
      deep: true,
    },
    "item.pivot.visible_to": {
      handler: function(value) {
        if(this.itemType.toLowerCase() != "program" && this.checkVisibility) {
          if(this.disableVisibility || this.showCount) {
            this.isCardHidden = false;
          } else {
            const visibility = JSON.parse(value);
            if (!value || (visibility instanceof Array && visibility.length === 0)) {
              this.isCardHidden = true;
            } else {
              let roles = Object.keys(visibility);
              this.isCardHidden = roles.length === 0 || (roles.length === 1 && roles[0] === "organization-admin");
            }
          }
        }
      },
      deep: true,
    },
  },
  computed: {
    getAvatarText() {
      return this.item.title.charAt(0).capitalize();
    },
    getAvatarColor() {
      if (this.locked) {
        return "#a4a6a9";
      } else if (this.isCardHidden) {
        return "initial";
      }
      return this.avatarColor;
    },
  },
  methods: {
    onVisibilityStatus(hiddenStatus) {
      this.isCardHidden = !this.disableVisibility && hiddenStatus && !this.showCount && this.checkVisibility;
    },
    onCardClick() {
      if(!this.processing && !this.altProcessing) {
        if(this.showCount) {
          this.$emit("showMore");
        } else if(this.locked) {
          this.$emit("onClick", this.item);
        } else if(this.href) {
          this.$router.push(this.href);
        } else {
          this.$emit("onClick");
        }
      }
    },
  },
};
</script>

<style lang="scss">
.program-card {
  p {
    margin: 0px !important;
  }
  .content {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    line-height: 1.4; 
    color: #000000; 
    font-size: 14px; 
    max-height: 70px;
  }
  .v-card__title {
    border-bottom: none !important;
  }
  .mentor-chip {
    color: #000000;
    font-size: 13px;
    font-weight: normal;
    cursor: pointer;
    min-width: 70px;
  }
}
.hide-blur {
  .v-overlay.v-overlay--active .v-overlay__scrim {
    backdrop-filter: none !important;
  }
}
.hidden-card {
  -webkit-backdrop-filter: blur(15px);
  backdrop-filter: blur(15px);
  background-color: rgba(47, 53, 63, 0.75) !important;
  box-shadow: 0 3px 8px 0 rgba(216, 220, 240, 0.5) !important;
}
.view-options .v-label {
  font-size: 12px;
  font-weight: 800;
  color: #252526;
}
</style>
