<template>
  <div :class="{ 'mr-3': !isForDashboard }">
    <VMenu
      left
      :attach="attach"
      :top="offsetY"
      :offset-y="offsetY"
      :close-on-content-click="true"
    >
      <template #activator="{ on, attrs }">
        <VBtn
          v-bind="attrs"
          v-on="on"
          outlined
          height="44px"
          width="44px"
          min-width="44px"
          style="background-color: white; border-color: #dedede;"
          @click.prevent
        >
          <VIcon
            color="#252526"
            style="font-size: 28px"
          >
            mdi-dots-horizontal
          </VIcon>
        </VBtn>
      </template>
      <VList>
        <VListItem
          v-if="$isUserAdmin && $viewProgramAsAdmin"
          @click="$emit('renameFeature')"
        >
          <VIcon
            size="20px"
            color="#9C27B0"
            class="pr-2"
          >
            mdi-rename-box
          </VIcon>
          <VListItemTitle>Rename</VListItemTitle>
        </VListItem>
        <VListItem
          v-if="($isUserAdmin && $viewProgramAsAdmin) || checkMentorPermission(section.name.singularize(), model)"
          @click="$emit('addSection')"
        >
          <VIcon
            size="20px"
            color="#ffc015"
            class="pr-2"
          >
            mdi-plus
          </VIcon>
          <VListItemTitle>Create New</VListItemTitle>
        </VListItem>
        <VListItem
          v-if="$isUserAdmin && $viewProgramAsAdmin"
          @click="$emit('toggleAttach')"
        >
          <VIcon
            size="20px"
            color="info"
            class="pr-2"
          >
            mdi-book-plus
          </VIcon>
          <VListItemTitle>Attach Existing</VListItemTitle>
        </VListItem>
        <VListItem
          v-if="isForDashboard"
          @click="$emit('toggleTab')"
        >
          <VIcon
            size="20px"
            color="#FF9800"
            class="pr-2"
          >
            mdi-application-cog
          </VIcon>
          <VListItemTitle>{{ $isUserAdmin && $viewProgramAsAdmin ? "Manage" : "View" }} All</VListItemTitle>
        </VListItem>
        <VListItem
          v-if="section.name == 'lessons' && !isUserStudentForProgram(model) && !$viewProgramAsStudent && model[section.name].length > 0"
          @click="$emit('reorder')"
        >
          <VIcon
            size="20px"
            color="#66BB6A"
            class="pr-2"
          >
            mdi-sort
          </VIcon>
          <VListItemTitle>Reorder</VListItemTitle>
        </VListItem>
      </VList>
    </VMenu>
  </div>
</template>

<script>
export default {
  props: {
    section: {
      type: Object,
      default: null,
    },
    model: {
      type: Object,
      default: null,
    },
    isForDashboard: {
      type: Boolean,
      default: false,
    },
    offsetY: {
      type: Boolean,
      default: false,
    },
    attach: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    getProperHref() {
      if (this.isUserStudentForProgram(this.model) || this.$viewProgramAsStudent) {
        return `/programs/${this.model.id}#${this.section.url}`;
      }
      return (
        this.getFullyQualifiedUrlForItem(`${this.section.routeName}`) +
        `?program=${this.model.id}&name=${this.model.title}`
      );
    },
  },
};
</script>
