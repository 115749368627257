var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('VCard',{class:{ 
      'd-flex align-center justify-center': true,
      'dragging': _vm.isDragging,
      'processing': _vm.isProccessing,
    },staticStyle:{"font-size":"15px","border-radius":"10px"},attrs:{"color":"#FAF9FF","height":"110px","width":"720px"},on:{"dragover":function($event){$event.preventDefault();return _vm.onDragover.apply(null, arguments)},"dragleave":function($event){$event.preventDefault();return _vm.onDragleave.apply(null, arguments)},"drop":function($event){$event.preventDefault();return _vm.onDrop.apply(null, arguments)},"click":_vm.triggerFileInput}},[_c('VFileInput',{ref:"fileInput",staticClass:"d-none",attrs:{"single-line":"","hide-input":"","accept":_vm.accept},on:{"change":_vm.onFileChange}}),(_vm.isProccessing)?_c('div',{staticClass:"ma-4 d-flex justify-center align-center",style:({
        background: `url(${_vm.ProgressImage})`,
        backgroundSize: '100% 100%',
        backgroundRepeat: 'no-repeat',
        height: '42px',
        width: '480px',
      })},[_c('h3',{staticClass:"text-body-2 font-weight-bold"},[_vm._v(" Your file is being uploaded... ")])]):[_c('img',{staticClass:"mr-1",attrs:{"src":require("@/images/spreadsheet-icon.png")}}),_vm._v(" Drag and drop your CSV here or click to select file ")]],2),(_vm.invalidFileType || _vm.errorMessage)?_c('p',{staticClass:"d-flex align-center error--text mt-4"},[_c('VIcon',{staticClass:"mr-2",attrs:{"color":"error"}},[_vm._v(" mdi-information ")]),_c('span',{staticStyle:{"font-size":"15px"}},[_vm._v(" "+_vm._s(_vm.errorMessage || "Invalid file type. Please upload a valid file type.")+" ")])],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }