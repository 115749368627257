<template>
  <div>
    <template v-if="input.type == 'Text'">
      <p
        class="formatted body-1"
        v-html="input.value"
        v-linkified
      />
    </template>
    <template v-else-if="input.type == 'Textarea' || input.type == 'TextArea'">
      <p
        v-html="compact ? (input.value || '').substr(0, 240)+((input.value || '').length > 240 ? ' ...' : '') : input.value"
        v-linkified
        class="formatted body-1"
        :style="{
          display: 'inline'
        }"
      />
      <VBtn
        v-if="compact && input.value && input.value.length > 240"
        small
        raised
        class="ml-3"
        style="display: inline"
        @click="onTogglePreview"
      >
        MORE <VIcon small>
          zoom_in
        </VIcon>
      </VBtn>
    </template>
    <template v-else-if="isVideoFile">
      <template v-if="compact">
        <VBtn
          v-if="hasUrl"
          @click="onTogglePreview"
        >
          View Video
        </VBtn>
        <template v-else>
          No Content
        </template>
      </template>
      <template v-else>
        <div class="video-container">
          <video
            controls
            autoPictureInPicture="true"
          >
            <source
              :src="(input.value || {}).url"
              :type="(input.value || {}).type"
            >
            Sorry, your browser doesn't support embedded videos.
          </video>
        </div>
      </template>
    </template>
    <template v-else-if="isFile(input)">
      <template v-if="compact">
        <VBtn
          v-if="hasUrl"
          @click="onTogglePreview"
        >
          View File: {{ previewFileType }}
        </VBtn>
        <template v-else>
          No Content
        </template>
      </template>
      <template v-else>
        <h3>Files</h3>
        <FilesList
          :readonly="true"
          :files="[input.value instanceof Object ? input.value : {}]"
        />
      </template>
    </template>
    <template v-else-if="isGoogleFile(input)">
      <template v-if="compact">
        <VBtn
          v-if="hasUrl"
          @click="onTogglePreview"
        >
          View Google File
        </VBtn>
        <template v-else>
          No Content
        </template>
      </template>
      <template v-else>
        <h3>Google Files</h3>
        <FilesList
          :readonly="true"
          :files="[input.value instanceof Object ? input.value : {}]"
        />
      </template>
    </template>
    <template v-else-if="input.type == 'Rating'">
      <VRating
        v-model.number="input.value"
        large
        readonly
        clearable
        slot="append"
        background-color="primary"
        color="primary"
        :disabled="true"
        :length="(input.options || {}).length || 5"
      />
    </template>
    <template v-else-if="(input.options || {}).type === 'Dropdown'">
      <span
        v-if="compact || readonly"
        class="body-1"
      >
        {{ input.value }}
      </span>
      <template v-else>
        <Autocomplete
          outlined
          small
          chips
          :readonly="readonly"
          :clearable="!readonly"
          :value="input.value"
          :items="getInputOptionsOptions(input)"
          :multiple="isOptionMultiple(input)"
          :placeholder="valuePlaceholder(input)"
          :value-comparator="(a, b) => a == b"
          @input="() => {}"
        />
      </template>
    </template>
    <template v-else-if="isOptionsInput(input.type)">
      <template v-if="compact">
        {{ Array.isArray(input.value) ? input.value.join(', ') : input.value }}
      </template>
      <template v-else>
        <template v-if="(input.options instanceof Object)">
          <VList dense>
            <VListItem
              v-for="(choice, choiceKey) in (getInputOptionsOptions(input) || [])"
              :key="`choice-${choiceKey}`"
              :class="{
                'mt-2': true,
                'green lighten-4': !getOptionsInputTypeIs(input, 'Dropdown') && isOptionSelected(input, choiceKey, choice) === true
              }"
            >
              <VListItemAvatar v-if="!getOptionsInputTypeIs(input, 'Dropdown')">
                <VBtn
                  fab
                  small
                  elevation="0"
                  color="info"
                  style="cursor: move"
                >
                  {{ 1+choiceKey }}
                </VBtn>
              </VListItemAvatar>
              <VListItemAction v-if="!getOptionsInputTypeIs(input, 'Dropdown')">
                <VBtn
                  icon
                  disabled
                >
                  <VIcon
                    color="primary"
                    v-if="isOptionSelected(input, choiceKey, choice) === true"
                  >
                    {{ isOptionMultiple(input) ? 'check_box' : 'radio_button_checked' }}
                  </VIcon>
                  <VIcon v-else>
                    {{ isOptionMultiple(input) ? 'check_box_outline_blank' : 'radio_button_unchecked' }}
                  </VIcon>
                </VBtn>
              </VListItemAction>
              <VListItemTitle style="overflow: visible; white-space: break-spaces">
                {{ choice }}
              </VListItemTitle>
            </VListItem>
          </VList>
        </template>
      </template>
    </template>
    <template v-else-if="isLinkedVideo">
      <template v-if="compact">
        <VBtn
          v-if="hasUrl"
          @click="onTogglePreview"
        >
          View Video
        </VBtn>
        <template v-else>
          No Content
        </template>
      </template>
      <template v-else>
        <VRow column>
          <VCol
            justify-content-center
            align-items-center
            :class="{
              'responsive-video': input.value && input.value.length
            }"
          >
            <iframe
              v-if="input.value && isValidUrl"
              :src="getVideoUrl(input.value)"
              frameborder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
              class="py-4"
              style="margin-left: auto; margin-right: auto;"
            />
            <VAlert
              v-else
              type="info"
              :value="true"
            >
              No video
            </VAlert>
          </VCol>
        </VRow>
      </template>
    </template>
    <template v-else-if="isUrlInput(input.type)">
      <p
        v-if="previewMode && getInputCaption(input) != null"
        v-html="getInputCaption(input)"
        v-linkified
        class="caption pl-1"
      />
      <VBtn
        small
        tile
        target="_blank"
        :href="(getQualifiedUrl(input.value) || '#')+''"
        :text="!getInputOptions(input).asButton"
        :color="getInputOptions(input).backgroundColor"
        :class="{
          'pl-0 py-0 px-2': !getInputOptions(input).asButton
        }"
        :style="{
          color: getInputOptions(input).textColor,
        }"
      >
        {{ input.name }}
        <VIcon
          small
          class="ml-1"
        >
          launch
        </VIcon>
      </VBtn>
      <div
        :style="{
          display: 'inline-block'
        }"
      >
        <VChip
          v-if="!compact && $isUserAdmin"
          small
          label
          class="ml-1 mr-1"
          :color="input.click_activity_for_user_count ? 'success' : 'error'"
        >
          Visited {{ input.click_activity_for_user_count || 0 }} time(s)
        </VChip><small v-if="previewMode">(NOTE: This stat is only visible to admins)</small>
      </div>
    </template>
    <template v-else>
      <template v-if="compact">
        <Tooltip
          :button-text="input.value"
          :title="input.value"
        />
      </template>
      <template v-else>
        <p
          class="formatted pb-4 body-1"
          v-html="input.value || (input.type || 'Text').titleize()"
          v-linkified
        />
      </template>
    </template>
    <Preview
      v-if="dialogs.preview"
      :files="[previewFile]"
      @toggle="onTogglePreview"
    />
  </div>
</template>

<script>
import DynamicInputsMixin from "@/mixins/DynamicInputs";
import DownloadsFiles from "@/mixins/DownloadsFiles";
import Preview from "@/components/Elements/Dialogs/Preview";
export default {
  name: "DynamicInput",
  mixins: [DynamicInputsMixin, DownloadsFiles],
  components: {
    Preview,
  },
  props: {
    readonly: {
      type: Boolean,
      default: false,
    },
    compact: {
      type: Boolean,
      default: false,
    },
    previewMode: {
      type: Boolean,
      default: false,
    },
    input: {
      type: Object,
      default: () => {},
    },
    choiceMode: {
      type: String,
      default: "",
    },
    inputChoices: {
      type: Array,
      default: () => [],
    },
    title: {
      type: String,
      default: "",
    },
  },
  data() {
    return {};
  },
  computed: {
    choices() {
      return this.choiceMode == "user"
        ? this.inputChoices
        : Object.assign({}, this.localChoices, this.inputChoices);
    },
    isLinkedVideo() {
      return this.input.type == "Video_Link";
    },
    isVideoFile() {
      if (this.isFile(this.input)) {
        const value =
          this.input.value instanceof Object ? this.input.value : {};
        const type =
          value.type || this.input.simple_type || this.input.type || "";
        return type.toLowerCase() === "video" || /^video/.test(type);
      }
      return false;
    },
    isValidUrl() {
      return (
        this.hasUrl && /^http|^\/\//.test(this.getVideoUrl(this.input.value))
      );
    },
    hasUrl() {
      return (this.previewFile || {}).url != null;
    },
    previewFileType() {
      return (
        (this.previewFile.name || "").split(".").last() || ""
      ).toUpperCase();
    },
    previewFile() {
      const input = this.input;
      if (!input) {
        return {};
      }
      if (this.isFile(input)) {
        return input.value || {};
      } else if (
        (input.value instanceof Object && this.getIsFromGoogle(input.value)) ||
        this.isVideoFile
      ) {
        return input.value;
      } else if (this.isLinkedVideo) {
        return { type: input.type, url: input.value, name: input.name };
      } else {
        return input;
      }
    },
  },
};
</script>

<style lang="scss">
@import "../../../styles/variables.scss";

:root {
  .dynamic-input-add.layout.row {
    margin-top: 15px !important;
  }

  .remove-dynamic-input .v-btn__content {
    text-transform: none;
    color: $font-light-color;
  }

  .remove-dynamic-input:hover .v-btn__content {
    color: #ec4a4a;
  }

  .dynamic-input-container .v-input__append-outer::before {
    content: "";
    height: 40%;
    width: 1px;
    position: absolute;
    right: 0;
    top: 30%;
    background-color: #eaeaea;
  }
  .caption {
    a {
      text-decoration: underline;
    }
  }

  .video-container {
    object-fit: fill;
    max-height: 300px;
    text-align: center;
    position: relative;
    padding-bottom: 56.25%; /* 16:9 */
    height: 0;
  }

  video {
    object-fit: contain;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
</style>